import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PublicComponent } from './layouts/public/public.component';
import { NoAuthGuard } from './guards/no-auth.guard';
import { AuthGuard } from './guards/auth.guard';
import { AppURL } from './app.url';

const bestRoutes: Routes = [
  // {
  //   path: '',
  //   canActivate: [NoAuthGuard],
  //   component: PublicComponent,
  //   loadChildren: () => import('./modules/auction-page/auction-page.module').then(m => m.AuctionPageModule),
  //   data: { title: 'DeeAuction' }
  // },
  // {
  //   path: '',
  //   canActivateChild: [AuthGuard],
  //   redirectTo: AppURL.management,
  //   pathMatch: 'full'
  // },
  { path: '', redirectTo: AppURL.account, pathMatch: 'full' },
  {
    path: AppURL.management,
    canActivateChild: [AuthGuard],
    component: PublicComponent,
    loadChildren: () => import('./modules/your-management/your-management.module').then(m => m.YourManagementModule),
    data: { title: '' }
  },
  {
    path: AppURL.packages,
    canActivateChild: [NoAuthGuard],
    component: PublicComponent,
    loadChildren: () => import('./modules/price-page/price-page.module').then(m => m.PricePageModule),
    data: { title: '' }
  },
  // {
  //   path: '',
  //   canActivateChild: [AuthGuard],
  //   loadChildren: () => import('./modules/shop-page/shop-page.module').then(m => m.ShopPageModule),
  //   data: { title: '' }
  // },
  {
    path: 'auctions',
    canActivate: [NoAuthGuard],
    component: PublicComponent,
    loadChildren: () => import('./modules/best-car-pages/best-car-pages.module').then(m => m.BestCarPagesModule),
    data: { title: 'DeeAuction' }
  },
  {
    path: AppURL.auctionDetail,
    canActivateChild: [AuthGuard],
    component: PublicComponent,
    loadChildren: () => import('./modules/auction-detail/auction-detail.module').then(m => m.AuctionDetailModule),
    data: { title: '' }
  },
  // {
  //   path: 'managements',
  //   canActivate: [AuthGuard],
  //   component: PublicComponent,
  //   loadChildren: () => import('./modules/your-management/your-management.module').then(m => m.YourManagementModule),
  //   data: { title: 'DeeAuction' }
  // },
  // {
  //   path: 'about',
  //   component: PublicComponent,
  //   loadChildren: () => import('./modules/about-page/about-page.module').then(m => m.AboutPageModule),
  //   data: { title: '' }
  // },
  // {
  //   path: 'how-to',
  //   component: PublicComponent,
  //   loadChildren: () => import('./modules/how-to-page/how-to-page.module').then(m => m.HowToPageModule),
  //   data: {
  //     title: ''
  //   }
  // },
  // {
  //   path: 'notification',
  //   component: PublicComponent,
  //   loadChildren: () => import('./modules/notification/notification.module').then(m => m.NotificationModule),
  //   data: {
  //     title: ''
  //   }
  // },
  {
    path: AppURL.account,
    loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule)
  },
  {
    path: 'safe',
    loadChildren: () => import('./modules/safe/safe.module').then(m => m.SafeModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot([...bestRoutes], {
      scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
