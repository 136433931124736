import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  paymentHub;

  payment$ = new BehaviorSubject<any>(null);

  constructor() { }

  getPaymentStatus(paymentCode: string) {
    return this.paymentHub.server.getPaymentStatus(paymentCode);
  }

  nextPayment(payment: any) {
    this.payment$.next(payment);
  }
}
