import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  constructor(private http: HttpClient) {}

  getListOrder() {
    return this.http.get<any>('orders');
  }

  InsertUpdateListOrder(items) {
    return this.http.post<any>('orders', items);
  }

  UpdateQuantityListOder(items, id) {
    return this.http.put<any>(`orders/${id}`, items);
  }

  DeleteListOrder(id) {
    return this.http.delete<any>(`orders/${id}`);
  }

  CheckOutChooseOrder(items) {
    return this.http.post<any>('orders/checkout', items);
  }

  ApplyVoucher(items, orderId) {
    return this.http.put<any>(`orders/${orderId}/voucher`, items);
  }

  DeleteVoucher(orderId) {
    return this.http.delete<any>(`orders/${orderId}/voucher`);
  }

  getCountItemsOrder() {
    return this.http.get<any>('orders/items');
  }

  //Tracking

  getListTraickingBuyer(pagi) {
    const pageParams = `page=${pagi.page}&limit=${pagi.limit}&name=${pagi.name}&filter=${pagi.filter}&status=${pagi.status}&firstDate=${pagi.firstDate}&secondDate=${pagi.secondDate}&search=${pagi.search}`;

    return this.http.get<any>(`orders/tracking/buyer?${pageParams}`);
  }
  getListTraickingSeller(pagi) {
    const pageParams = `page=${pagi.page}&limit=${pagi.limit}&name=${pagi.name}&filter=${pagi.filter}&status=${pagi.status}&firstDate=${pagi.firstDate}&secondDate=${pagi.secondDate}&search=${pagi.search}`;

    return this.http.get<any>(`orders/tracking/seller?${pageParams}`);
  }

  getDetailPaymentProduct(orderId) {
    return this.http.get<any>(`orders/tracking/${orderId}`);
  }

  UpdatePaymentSlip(item, orderId) {
    return this.http.put<any>(`orders/${orderId}/paymentslip`, item);
  }

  UpdateShippingPayment(item, orderId) {
    return this.http.put<any>(`orders/${orderId}/shipping`, item);
  }
  UpdateRejectStatus(item, orderId) {
    return this.http.put<any>(`orders/${orderId}/reject`, item);
  }

  CheckNotificationPaymentModule() {
    return this.http.get<any>('orders/order_auction/exclamation');
  }

  CheckNotificationPaymentStore() {
    return this.http.get<any>('orders/exclamation');
  }

  CheckNotificationPaymentStoreBuyer() {
    return this.http.get<any>('orders/exclamation/buyer');
  }

  CheckNotificationPaymentStoreSeller() {
    return this.http.get<any>('orders/exclamation/seller');
  }

  CheckNotificationPaymentAuction() {
    return this.http.get<any>('auction_tracking/exclamation');
  }
  CheckNotificationPaymentAuctionWinner() {
    return this.http.get<any>('auction_tracking/exclamation/winner');
  }
  CheckNotificationPaymentAuctionOwner() {
    return this.http.get<any>('auction_tracking/exclamation/owner');
  }
}
