import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { WINDOW } from '@ng-toolkit/universal';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { AppURL } from 'src/app/app.url';
import { Inbox } from 'src/app/interfaces/inbox';
import { NavbarNamespace } from 'src/app/interfaces/navbar.interface';
import { Reply } from 'src/app/interfaces/reply';
import { KEY_TOKEN, KEY_USER } from 'src/app/models/app.models';
import { AuctionService } from 'src/app/services/auction.service';
import { CustomerService } from 'src/app/services/customer.service';
import { InboxService } from 'src/app/services/inbox.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ShopService } from 'src/app/services/shop.service';
import { UserService } from 'src/app/services/user.service';
import { ModalLoginComponent } from 'src/app/shared/components/modal-login/modal-login.component';
import { LoginService } from 'src/app/shared/services/login/login.service';
import { OrderService } from 'src/app/shared/services/order/order.service';
import { Logger } from 'src/app/utils/console';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { AuthenticationService } from '../../services/authentication.service';
import { LocalStorageService } from '../../services/local-storage.service';
import { filter, map, mergeMap } from 'rxjs/operators';
import { AccountUrl } from 'src/app/modules/account/account.url';
import moment from 'moment';
import { YouManagementUrl } from 'src/app/modules/your-management/your-management.url';
import { PaymentService } from 'src/app/services/payment.service';
import { LanguagePipe } from 'src/app/shared/pipes/language.pipe';

@Component({
  selector: 'best-navbar',
  templateUrl: './best-navbar.component.html',
  styleUrls: ['./best-navbar.component.scss'],
  providers: [LanguagePipe]
})
export class BestNavbarComponent implements OnInit {
  /** เมนู */
  menuItems: NavbarNamespace.IMenu[] = [
    // {
    //   name: 'ร้านค้า',
    //   link: ['/'],
    //   matchUrlLists: [
    //     '/auction-more'
    //   ],
    //   exact: true
    // },
    // { name: 'เกี่ยวกับ', link: ['/', AppURL.about], exact: true },
  ];

  @ViewChild('loginModal', { static: true }) loginModal: TemplateRef<any>;
  @ViewChild('rankModal', { static: true }) rankModal: TemplateRef<any>;
  @ViewChild('lossModal', { static: true }) lossModal: TemplateRef<any>;

  @ViewChild('verifyModal', { static: true }) verifyModal: TemplateRef<any>;
  modalRefVerify: BsModalRef;

  api = environment.api;

  modalRefLogin: BsModalRef;
  modalRefRank: BsModalRef;
  modalRefLoss: BsModalRef;

  routerActive = '';

  CountinCart = 0;

  notificationHub: any;
  notiData: any;
  notiItems: any[];
  notifyCount: number;
  onNotify = false;

  auctionHub: any;
  auctionStared: any;

  customer: ICustomer;
  Subscriptions: Subscription[] = [];

  urlUse: string = '';

  languageItems: {id: string, src: string, name: string}[] = [
    { id: 'lc', src: './assets/icons/th-icon.png', name: 'ภาษาไทย' },
    { id: 'en', src: './assets/icons/eng-icon.png', name: 'English' },
  ];
  selectedLanguage: {id: string, src: string, name: string} = { id: 'lc', src: './assets/icons/th-icon.png', name: 'ภาษาไทย' };
  selectLange: string = 'lc';
  itemCoreLg: any;

  urlImage: string;
  imageBase = environment.content;
  environmentData = environment;
  unshowUserMode: boolean = false;
  AppURL = AppURL;
  urlImageProfile =
    'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT8Cuyq7AdKhhmAL6ceV-s8oLT630oo9uMOWPjH994GigCH5S3q';
  shopURL: string = '';
  verifyStore: boolean = true;
  packageStore: boolean = true;
  tabHidden: boolean = null;
  startHiddenTab: boolean = false;
  merchantStatus = false;
  storeId: number = null;

  constructor(
    private modalService: BsModalService,
    private storage: LocalStorageService,
    private appService: AppService,
    private auth: AuthenticationService,
    private loginService: LoginService,
    @Inject(WINDOW) private window: Window,
    private userSv: UserService,
    private router: Router,
    private route: ActivatedRoute,
    public shopService: ShopService,
    private orderService: OrderService,
    private notiSv: NotificationService,
    private paymentSv: PaymentService,
    private auctionSv: AuctionService,
    private svCustomer: CustomerService,
    private svInbox: InboxService,
    private languageSer: LanguagePipe,
  ) {
    let lange = localStorage.getItem('language');
    if (lange) {
      this.selectLange = lange;
      this.selectedLanguage = this.languageItems[0];
      if (lange !== 'lc') {
        this.selectedLanguage = this.languageItems[1];
      }
    } else {
      this.languageUse(this.selectLange);
    }

    let urlList = this.router.url.split('/');
    if (urlList) {
      if (urlList[1] === 'managements') {
        this.unshowUserMode = true;
        if (urlList.length === 2) { this.router.navigate(['/']);}
      } else {
        this.unshowUserMode = false;
      }
    }

    this.Subscriptions.push(
      this.svCustomer.Customer$.subscribe(customer => {
        this.customer = customer;
        this.verifyCustomerStatus();
        if (this.customer && this.customer.ProfileImg !== null && this.customer.ProfileImg !== '') {
          this.urlImage = `${this.imageBase + this.customer.ProfileImg}`;
        }

        let url = this.router.url.split('/');
        if (url[1] === AppURL.management) {
          this.shopURL = decodeURI(decodeURI(url[2]));
        }
        
        if (url[1] === AppURL.notification) {
          this.onNotify = true;
        }
      }),
      this.svCustomer.updateCustomer$.subscribe(local => {
        if (!local) {
          this.svCustomer.getCustomerInfo().subscribe(customer => {
            this.svCustomer.Customer$.next(customer);
          });
        } else {
          this.svCustomer.Customer$.next(this.storage.get(KEY_USER));
        }
      }),
      this.appService.subject.asObservable().subscribe(res => {
        if (res) {
          if (res.statusLogin) {
            return this.openModalLogin();
          }
          if (!res.statusLogin) {
            return this.openModalRegister();
          }
          if (res.rankWinner === true) {
            return this.openModalRank();
          }
        }
      }),
      this.appService.login.subscribe(res => {
        if (res) {
          this.openModalLogin();
        }
      }),
      this.userSv.winner.subscribe(res => {
        if (!res) {
          return;
        }
        this.openModalRank();
      }),
      this.userSv.loser.subscribe(res => {
        if (!res) {
          return;
        }
        this.openModalLoss();
      }),
      this.appService.activeRouter.subscribe(res => {
        this.urlUse = this.router.url;
        this.routerActive = res;
      }),
      this.notiSv.notiItems$.subscribe(res => {
        this.notiData = res;
        this.notiItems = [];
        if (res) {
          res.items.forEach((item: any, index: number) => {
            if (item.CreatedAt) {
              item['settime'] = moment(item.CreatedAt).format('YYYYMMDDHHmm');
              item.settime = moment(item.settime, "YYYYMMDDHHmm").fromNow();
              if (this.selectLange === 'lc') {
                let checkText = false;
                if (item.settime === 'a few seconds ago') { 
                  item.settime = 'ไม่กี่วินาทีผ่านมา';
                } else {
                  let listTime = item.settime.split(" ");
                  let settext = listTime[0] !== 'an' && listTime[0] !== 'a' ? listTime[0] : 'ไม่กี่';
                  if (listTime[1] === 'seconds' || listTime[1] === 'seconds') {
                    settext += ' วินาที ผ่านมา';
                    checkText = true;
                  } else if (listTime[1] === 'minute' || listTime[1] === 'minutes') {
                    settext += ' นาที ผ่านมา';
                    checkText = true;
                  } else if (listTime[1] === 'hour' || listTime[1] === 'hours') {
                    settext += ' ชม. ผ่านมา';
                    checkText = true;
                  } else if (listTime[1] === 'day' || listTime[1] === 'days') {
                    checkText = true;
                    settext += ' วัน ผ่านมา';
                  } else if (listTime[1] === 'month' || listTime[1] === 'months') {
                    checkText = true;
                    settext += ' เดือน ผ่านมา';
                  } else if (listTime[1] === 'year' || listTime[1] === 'years') {
                    settext += ' ปี ผ่านมา';
                    checkText = true;
                  }
                  if (checkText) {
                    item.settime = settext;
                  }
                }
              }
            }
              // this.notiItems.push(item);
            if (index < 3) {
              this.notiItems.push(item);
            }
          });
        }  
      }),
      this.notiSv.iconNotify$.subscribe(res => {
        if (!res) return;
        if (res.name === 'notify') {
          this.onNotify = res.value;
        }
      }),
      this.appService.ResetCountCart.subscribe(res => {
        if (res) {
          this.getCountItemsOrder();
        }
      }),
      this.auctionSv.auctionStarted.subscribe(res => {
        this.auctionStared = res;
      }),
      this.appService.auctionHubStartConnect.subscribe(res => {
        let [auction, inboxs, notification] = [true, false, false];
        if (this.storage.get(KEY_TOKEN)) {
          notification = true;
          inboxs = true;
        }
        this.connectionHub(auction, inboxs, notification);
      }),
      this.svCustomer.NavbarTab$.subscribe(res => {
        // console.log(res);
        if (res.name === 'verifyStore') {
          this.verifyStore = res.value;
        }
        if (res.name === 'packageStore') {
          this.packageStore = res.value;
        }
        if (res.name === 'onPayment') {
          this.connectHubPayment(res.value);
        }
      }),
      this.shopService.storeOpen.subscribe(data => {
        this.merchantStatus = data.status;
        this.storeId = data.id;
      })
    );

    this.router.events.pipe(filter(evt => evt instanceof NavigationEnd)).subscribe(event => {
      if (this.appService.isBrowser) {
        $.connection.hub.url = `${this.appService.apiHost}signalr`;
        //enabvle log
        $.connection.hub.logging = true;
        $.connection.hub.qs = {
          token: this.storage.get(KEY_TOKEN)
        };

        const paths = location.pathname.split('/');
        let [auction, inboxs, notification] = [false, false, false];
        if (paths.length > 2) {
          if (paths[4] === 'auction') {
            auction = true;
          }
        }

        if (this.storage.get(KEY_TOKEN)) {
          notification = true;
          inboxs = true;
          this.connectionHub(auction, inboxs, notification);
        }
      }
    });

    if (this.storage.get(KEY_TOKEN)) {
      if (this.svCustomer.Customer$.value) {
        this.svCustomer.updateCustomer$.next(true);
      } else {
        this.svCustomer.updateCustomer$.next();
      }
    }
  }

  ngOnDestroy() {
    if (this.appService.isBrowser) {
      if (this.auctionStared && this.auctionHub && this.auctionSv.Auction) {
        if ($.connection.hub.state == $.signalR.connectionState.disconnected) return;
        this.auctionHub.server
          .leave(this.auctionSv.Auction.AucId)
          .done(() => {
            // Logger('leave doned');
          })
          .fail(err => {
            // Logger('leave failed', err);
          })
          .always(() => {
            $.connection.hub.stop();
          });
      } else {
        $.connection.hub.stop();
      }
    }
    this.Subscriptions.map(s => s.unsubscribe());
    this.Subscriptions = [];
  }

  ngOnInit() {}

  /** ตรวจสอบ verify customer status */
  verifyCustomerStatus() {
    if (!this.customer) return;
    const { CustomerStatus } = this.customer;
    if (CustomerStatus === 'dea') {
      this.openModalLogin();
    }
  }

  /** โหลดข้อมูล ModalLoginComponent */
  onLoadModalLoginComponent(component: ModalLoginComponent) {
    if (!this.customer) return;
    const { CustomerStatus } = this.customer;
    if (CustomerStatus === 'dea') {
      this.modalRefLogin.hide();
      component.openModalVerify();
    }
  }

  /** ไปที่หน้าร้านค้า */
  onGoToStore() {
    if (!this.customer.CustomerPhoneNoVerified) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: this.languageSer.transform('havenot_verified_identity')+'\n'+this.languageSer.transform('via_phone_number'),
        showConfirmButton: false,
        timer: 1500
      });
      this.openModalVerify();
      return;
    }
    if (this.customer.Store) return this.router.navigate(['/', 'shop', this.customer.Store]);
    this.router.navigate(['/', 'shop', 'create-shop']);
  }

  /** ตรวจสอบ active menu */
  isMenuActive(item: NavbarNamespace.IMenu) {
    let mathURL: boolean = false;
    const { matchUrlLists } = item;
    const url = this.router.serializeUrl(this.router.createUrlTree(item.link));
    if (item.exact) {
      mathURL = url === this.router.url;
    } else {
      mathURL = this.router.url.indexOf(url) >= 0;
    }
    if (matchUrlLists && matchUrlLists.length > 0 && !mathURL)
      matchUrlLists.forEach(mat => {
        if (!mathURL) mathURL = this.router.url.indexOf(mat) >= 0;
      });
    return mathURL;
  }

  openModalLogin() {
    // this.loginService.setModalPageName(0);
    // this.modalRefLogin = this.modalService.show(this.loginModal, {
    //   class: 'modal-md transparent',
    // });
    let listUrl = this.router.url.split('/');
    if (listUrl.length > 2 && listUrl[1] === 'shop') {
      this.appService.setURLToGo(['', 'shop', decodeURI(listUrl[2])]);
      this.router.navigate(['/', AppURL.account, decodeURI(listUrl[2]), AccountUrl.signin]);
      return;
    }
    this.router.navigate(['/', AppURL.account, AccountUrl.signin]);
  }

  openModalRegister() {
    // this.loginService.setModalPageName(1);
    // this.modalRefLogin = this.modalService.show(this.loginModal, {
    //   class: 'modal-md transparent'
    // });
    let listUrl = this.router.url.split('/');
    if (listUrl.length > 2 && listUrl[1] === 'shop') {
      this.appService.setURLToGo(['', 'shop', decodeURI(listUrl[2])]);
      this.router.navigate(['/', AppURL.account, decodeURI(listUrl[2]), AccountUrl.signup]);
      return;
    }
    this.router.navigate(['/', AppURL.account, AccountUrl.signup]);
  }

  openModalRank() {
    this.modalRefRank = this.modalService.show(this.rankModal, {
      class: 'modal-lg transparent mt-5'
    });
  }

  openModalLoss() {
    this.modalRefLoss = this.modalService.show(this.lossModal, {
      class: 'modal-lg transparent mt-5'
    });
  }

  closeModal() {
    if (this.modalRefRank) {
      this.modalRefRank.hide();
      this.modalRefRank = null;
    }
    if (this.modalRefLoss) {
      this.modalRefLoss.hide();
      this.modalRefLoss = null;
    }

    if (this.appService.isBrowser) {
      setTimeout(() => {
        if ($(this.window.document.body)) {
          $(this.window.document.body).removeClass('modal-open');
        }
        if ($('.modal-backdrop').length) {
          $('.modal-backdrop').remove();
        }
      }, 1000);
    }
  }

  onLogout() {
    Swal.fire({
      title: this.languageSer.transform('check_logout')+'?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: this.languageSer.transform('confirm'),
      cancelButtonText: this.languageSer.transform('cancel')
    }).then(result => {
      if (result.value) {
        this.auth
          .signOut(this.storage.get(KEY_TOKEN))
          .pipe(
            finalize(() => {
              this.storage.clear();
              this.router.navigate(['']);
              this.svCustomer.Customer$.next(null);
              this.svInbox.hideMessage();

              Swal.fire({
                position: 'center',
                icon: 'success',
                title: this.languageSer.transform('success_logout'),
                showConfirmButton: false,
                timer: 1500
              });
            })
          )
          .subscribe(res => {});
      }
    });
  }

  getCountItemsOrder() {
    this.orderService.getCountItemsOrder().subscribe(res => {
      this.CountinCart = res;
    });
  }

  delay() {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(true);
      }, 500);
    });
  }

  async connectionHub(auction = false, inboxs = false, notification = false) {
    if (this.appService.isBrowser) {
      const max = 10;
      let a = 0;
      let iInb = 0;
      let sumting = 0;

      if (auction) {
        while (a < max) {
          await this.delay();
          if (!($.connection as any).auctionHub) {
            a++;
          } else {
            a = max;
            await this.setAuctionHub();
          }
        }
      }

      if (inboxs) {
        while (iInb < max) {
          await this.delay();
          if (!($.connection as any).inboxHub) {
            iInb++;
          } else {
            iInb = max;
            await this.setInboxHub();
          }
        }
      }

      if (notification) {
        while (sumting < max) {
          await this.delay();
          if (!($.connection as any).notificationCustomerHub) {
            sumting++;
          } else {
            sumting = max;
            await this.setNotiHub();
          }
        }
      }

      if (!auction && !inboxs && !notification) return;

      $.connection.hub.url = `${this.appService.apiHost}signalr`;
      $.connection.hub
        .start()
        .done(() => {
          // Logger('hub started done');

          if (auction) {
            this.auctionHub.server
              .init(this.auctionSv.Auction.AucId)
              .done(() => {
                this.auctionSv.auctionStarted.next(true);
                // Logger('auction init doned');
              })
              .fail(err => {
                // Logger('auction init failed', err);
              });
          }

          if (inboxs) {
            this.svInbox.inboxHub.server
              .init()
              .done(() => {
                // Logger('inboxHub init doned');
              })
              .fail(err => {
                // Logger('inboxHub init failed', err);
              });
          }

          if (notification) {
            this.notiSv.init(1,15,'')
              .done(() => {
                // Logger('notificationCustomerHub init doned');
              })
              .fail(err => {
                // Logger('notificationCustomerHub init failed', err)
              } );
          }
        })
        .fail(err => {
          // Logger('hub started error', err);
        });
    }
  }

  async connectHubPayment(paymentcode: string) {
    let payment = (paymentcode? true: false);
    if (this.appService.isBrowser) {
      const max = 10;
      let countPayment = 0;
      if (payment) {
        while (countPayment < max) {
          await this.delay();
          if (!($.connection as any).paymentHub) {
            countPayment++;
          } else {
            countPayment = max;
            await this.setPaymentHub();
          }
        }
      }

      if (!payment) return;

      if (payment) {
        this.paymentSv.getPaymentStatus(paymentcode)
          .done(() => {
            // Logger('paymentSv init doned');
          })
          .fail(err => {
            // Logger('paymentSv init failed', err);
          });
      }
    }
  }

  setAuctionHub() {
    return new Promise(resolve => {
      this.auctionHub = ($.connection as any).auctionHub;
      this.auctionHub.client.updateAuction = auction => {
        this.auctionSv.auctionSubscription.next(auction);
      };
      this.auctionHub.client.updateChat = chat => {
        this.auctionSv.chatSubscription.next(chat);
      };
      this.auctionHub.client.updateAuctionFinish = (finish: IAuction) => {
        // Logger('auction finished', finish);
        this.auctionSv.finishSubscription.next(finish);
      };
      this.auctionHub.client.onCancel = canceled => {
        // Logger('auction canceled');
        this.auctionSv.cancelSubscription.next(canceled);
      };

      // Lobby
      this.auctionHub.client.Accesses = (status: string) => {
        this.auctionSv.customerLobbyStatus.next(status);
      };
      this.auctionHub.client.UpdateMembersRequest = members => {
        this.auctionSv.customerLobbyRequest.next(members);
      };

      resolve(true);
    });
  }

  setInboxHub() {
    return new Promise(resolve => {
      this.svInbox.inboxHub = ($.connection as any).inboxHub;

      this.svInbox.inboxHub.client.updateInboxes = (inboxes: Inbox[]) => {
        this.svInbox.inbox$.next(inboxes);
      };
      this.svInbox.inboxHub.client.updateReplies = (replies: Reply[]) => {
        this.svInbox.replies$.next(replies);
      };
      resolve(null);
    });
  }

  setNotiHub() {
    return new Promise(resolve => {
      this.notiSv.notiHub = ($.connection as any).notificationCustomerHub;

      this.notiSv.notiHub.client.onPushNotify = (notifyItem: any) => {
        this.notiSv.notiItems$.next(notifyItem);
      };

      resolve(null);
    });
  }

  setPaymentHub() {
    try {
      return new Promise(resolve => {
        this.paymentSv.paymentHub = ($.connection as any).paymentHub;
        this.paymentSv.paymentHub.client.paymentStatus = (paymentItem: any) => {
          // console.log(paymentItem);
          this.paymentSv.nextPayment(paymentItem);
        };
  
        resolve(null);
      })
    } catch (error) {
      // console.log(error);
    }
  }

  /** ไปหน้าสร้างสินค้า แต่ถ้าไม่มีร้านค้าจะไปที่หน้าสร้างร้านค้าก่อน */
  goSalePage() {
    if (this.customer.Store) {
      this.router.navigate(['/', 'shop', this.customer.Store, 'create-product']);
    } else {
      Swal.fire({
        position: 'center',
        icon: 'info',
        toast: true,
        showConfirmButton: false,
        timer: 4000,
        text: this.languageSer.transform('please_create_shop_before_products')
      });
      this.router.navigate(['/', 'shop', 'create-shop']);
    }
  }

  openModalVerify() {
    this.modalRefVerify = this.modalService.show(this.verifyModal, {
      class: 'modal-md transparent',
      ignoreBackdropClick: true
    });
  }

  /** ไปที่หน้าสร้างการประมูล */
  nextPageAuction() {
    if (!this.customer.CustomerPhoneNoVerified) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: this.languageSer.transform('havenot_verified_identity')+'\n'+this.languageSer.transform('via_phone_number'),
        showConfirmButton: false,
        timer: 1500
      });
      this.openModalVerify();
      return;
    }
    this.router.navigate(['/managements/auction']);
    // <!-- <a [routerLink]="['', 'managements', 'auction']"> -->
  }

  gotoURL() {
    var element = document.getElementById('navbar-expand-menu');
    element.classList.add('hide');
    element.classList.remove('show');
  }

  notifyCheck(func: string, type: string) : boolean {
    if (func === 'icon-verify') {
      return (type === 'RV' || type === 'RVS' || type === 'RVF'); 
    } else if (func === 'icon-joinAuction') {
      return ( type === 'RB' || type === 'RBS' || type === 'RBF');
    } else if (func === 'icon-auction') {
      return (type === 'AS' || type === 'AW' || type === 'AL');
    } else if (func === 'icon-store') {
      return (type === 'MVR' || type === 'MVS' || type === 'MVF');
    } else if (func === 'icon-card') {
      return (type === 'SP' || type === 'SA');
    }

    if (func === 'color-success') {
      return (type === 'RVS' || type === 'RBS' || type === 'AW' || type === 'MVS');
    } else if (func == 'color-error') {
      return (type === 'RVF' || type === 'RBF' || type === 'AL' || type === 'MVF');
    }
  }

  openpopUp() {
    if (this.notiItems && this.notiItems.length > 0) {
      this.notiSv.seen()
        .done(() => {
          // Logger('seen success');
          this.notiData.UnSeen = 0;
        })
        .fail(err => {
          // Logger('notificationCustomerHub seen failed', err)
        });
    }
  }

  nextPageNotify(index: number) {
    if (index === -1) {
      this.router.navigate(['', AppURL.management, this.shopURL, AppURL.notification]);
      return;
    }

    if (!(this.notiItems && this.notiItems.length > 0)) return;
    this.notiSv.read(this.notiItems[index].NotificationId)
    .done(() => {
      // Logger('read success');
      this.notiItems[index].Read = true; 
      let noti = this.notiItems[index]; 
      if (noti.Type === 'RV') {
        this.router.navigate(['', AppURL.management, this.shopURL,'manage-user', 'profile'],{queryParams: {user: noti.RefCode, tab: 3}});
      } else if (noti.Type === 'RVS' || noti.Type === 'RVF') { 
        this.openBidder(`${environment.bidder}/shop/${this.notiItems[index].RefCode}`);
      } else if (noti.Type === 'RB') { 
        let RefCode;
        try {
          RefCode = JSON.parse(noti.RefCode);
        } catch (error) {
            // console.log('Error parsing JSON:', error, noti.RefCode);
        }
        this.router.navigate(['', AppURL.auctionDetail, this.shopURL, RefCode.auc_code]);
      } else if (noti.Type === 'RBS' || noti.Type === 'RBF' || noti.Type === 'AS') { 
        let RefCode;
        try {
          RefCode = JSON.parse(noti.RefCode);
        } catch (error) {
            // console.log('Error parsing JSON:', error, noti.RefCode);
        }
        this.openBidder(`${environment.bidder}/auction/${RefCode.store_url}/${RefCode.auc_code}`);
      } else if (noti.Type === 'AW' || noti.Type === 'AL') {
        let RefCode;
        try {
          RefCode = JSON.parse(noti.RefCode);
        } catch (error) {
            // console.log('Error parsing JSON:', error, noti.RefCode);
        }
        this.openBidder(`${environment.bidder}managements/${RefCode.store_url}/auctions/bid-auction`);
      } else if (noti.Type === 'MVR' || noti.Type === 'MVS' || noti.Type === 'MVF') {
        this.router.navigate(['', AppURL.management, this.shopURL, YouManagementUrl.verifyIdentiry]);
      } else if (noti.Type === 'SP') {
        this.router.navigate(['', AppURL.management, this.shopURL, YouManagementUrl.payments, 'package']);
      } else if (noti.Type === 'SA') {
        this.router.navigate(['', AppURL.management, this.shopURL, YouManagementUrl.auction, 'add']);
      }
    })
    .fail(err => {
      // Logger('notificationCustomerHub read failed', err);
    });
  }

  openBidder(url: string) {
    let dataToken = localStorage.getItem(KEY_TOKEN);
    let frame: any;
    frame = document.getElementById('frameBidder');
    frame = frame.contentWindow;
    frame.postMessage({type: 'send', value: dataToken}, this.environmentData.bidder);
    window.open(url);
  }

  nextPage(funcNext: string) {
    if (funcNext === 'verifyStore') {
      this.router.navigate(['', AppURL.management, this.shopURL, YouManagementUrl.verifyIdentiry]);
      return;
    } else if (funcNext === 'packageStore') {
      this.router.navigate(['', AppURL.packages]);
      return;
    }
  }

  logoNext() {
    let customer = this.storage.get(KEY_USER);
    if (customer && !customer.Store) {
      this.router.navigate(['', AppURL.account, AccountUrl.createShop]);
      return;
    } else if (customer && customer.CustomerVerified !== 'V') {
      this.router.navigate(['', AppURL.management, customer.Store, YouManagementUrl.verifyIdentiry]);
      return;
    } else if (customer) {
      this.router.navigate(['', AppURL.management, customer.Store, YouManagementUrl.auctions]);
      return;
    }
    let loginCheck = this.storage.get(KEY_TOKEN);
    if (loginCheck) {
      this.router.navigate(['', AppURL.account, AccountUrl.createShop]);
      return;
    }
  }

  hiddenTab(status: boolean) {
    if (status === this.tabHidden || (!status && this.tabHidden === null && !this.startHiddenTab)) { return; }
    this.tabHidden = status;
    this.startHiddenTab = true;
  }

  onOpenStore() {
    let txt = '';
    if (this.merchantStatus) txt = this.languageSer.transform('open_store_display');
    else txt = this.languageSer.transform('shop_display_closed');
    this.shopService.enabledisable_merchant(this.storeId).subscribe(
      res => {
        Swal.fire({
          icon: 'success',
          text: txt,
          showConfirmButton: false,
          timer: 1500
        });
        this.merchantStatus = !this.merchantStatus;
        this.shopService.storeOpen.next({id: this.storeId, status: this.merchantStatus});
      },
      err => {
        Swal.fire({
          icon: 'error',
          text: err.error.Message,
          showConfirmButton: false,
          timer: 1500
        });
      }
    );
  }

  languageUse(key: any) {
    if (key && key.value) {
      this.selectLange = key.value.id;
      this.appService.languageUse(key.value.id);
    } else {
      this.selectLange = key;
      this.appService.languageUse(key);
    }
  }
}
