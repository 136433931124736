import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import moment from 'moment';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from './core/services/local-storage.service';
import { Title, Meta } from '@angular/platform-browser';
import { ValidatorFn, AbstractControl } from '@angular/forms';
import { isPlatformBrowser } from '@angular/common';
import { Logger } from './utils/console';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  private api = environment.api;

  public subject = new Subject<any>();
  public login = new Subject<any>();
  public modalDetail = new BehaviorSubject<any>(null);
  public activeRouter = new Subject<string>();
  public ResetCountCart = new Subject<boolean>();
  public auctionHubStartConnect = new Subject<any>();
  public urlTogo: any;
  
  public dateTimeNow: Date;
  public dateTimeNowAddMin: Date;

  public regexYT = RegExp(/^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/);

  title: string;
  keywords: string;
  description: string;
  constructor(
    private localStorage: LocalStorageService,
    private svTitle: Title,
    private svMeta: Meta,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.title = this.svTitle.getTitle();
    this.keywords = this.svMeta.getTag('name="keywords"').content;
    this.description = this.svMeta.getTag('name="description"').content;
  }

  updateDateTimeNow() {
    if (this.isBrowser) {
      return new Promise<any>(async (resolve, reject) => {
        if (this.dateTimeNow) {
          const nowFormat = +moment(this.dateTimeNow).format('YYYYMMDDHHmm');
          const nowLocalFormat = +moment().format('YYYYMMDDHHmm');
          if (nowFormat === nowLocalFormat) {
            return resolve();
          }
        }

        const dataDate = await this.xhrApi('GET', `${this.api}api/datetime/now`);
        const formatDate = `${dataDate.Current} +07:00`;
        this.dateTimeNow = moment(formatDate, 'MM/DD/YYYY HH:mm:ss aa ZZ')
          .utcOffset(7)
          .local(true)
          .toDate();
        this.dateTimeNowAddMin = moment(formatDate, 'MM/DD/YYYY HH:mm:ss aa ZZ')
          .utcOffset(7)
          .local(true)
          .add(0, 'minutes')
          .toDate();
        return resolve();
      });
    }
  }

  toBase64(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  youtubeParser(url) {
    const match = url.match(this.regexYT);
    return match && match[7].length === 11 ? match[7] : false;
  }

  xhrApi(method: 'GET' | 'POST' | 'PUT' | 'DELETE', url: string, header?) {
    return new Promise<any>((resolve, reject) => {
      if (this.isBrowser) {
        const http = new XMLHttpRequest();
        http.open(method, `${url}`);
        http.onreadystatechange = state => {
          if (http.readyState === 4 && http.status === 200) {
            try {
              const response = JSON.parse(http.responseText);
              resolve(response);
            } catch (error) {
              reject(error);
            }
          } else if (http.readyState === 4 && http.status !== 200) {
            reject(http.responseText);
          }
        };
        http.send();
      } else {
        resolve();
      }
    });
  }

  get apiHost(): string {
    const apiHost = this.localStorage.get('API_HOST');
    return apiHost || this.api;
  }

  handleValidatorSpanNumber(min = 0, max = null): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let error = null;
      if (control.value && typeof control.value === 'string') {
        const searchComma = control.value.search(',');
        const searchMinus = control.value.search('-');
        if (searchComma > 0) {
          if (!control.value.split(',').every(Number)) {
            error = { Message: 'ใส่ข้อมูลให้ถูกต้อง' };
          }
          if (!max) {
            if (!control.value.split(',').every(n => +n >= min)) {
              error = { Message: `ใส่ตัวเลขให้มากกว่า ${min}` };
            }
          } else {
            if (!control.value.split(',').every(n => +n > min && +n <= max)) {
              error = { Message: `ใส่ตัวเลขระหว่าง ${min}-${max}` };
            }
          }
          const checkLength = control.value.split(',');
          if (checkLength.length >= 4) { error = { Message: 'ใส่ข้อมูลราคาบิทของบอทได้ไม่เกิน 3 ราตา' };}
        } else if (searchMinus > 0) {
          const arr = control.value.split('-').map(Number);

          if (arr.length === 1) {
            const start = arr[0];
            if (!(start >= min)) {
              error = { Message: `ใส่ตัวเลขให้มากกว่า ${min}` };
            }
          } else if (arr.length === 2) {
            const start = arr[0];
            const end = arr[1];
            if (!max) {
              if (!(start >= min)) {
                error = { Message: `ใส่ตัวเลขให้มากกว่า ${min}` };
              } else if (start >= end) {
                error = { Message: `ใส่ตัวเลขจากน้อยไปมาก เช่น 60-180` };
              }
            } else {
              if (!(start >= min && end <= max)) {
                error = { Message: `ใส่ตัวเลขระหว่าง ${min}-${max}` };
              } else if (start >= end) {
                error = { Message: `ใส่ตัวเลขจากน้อยไปมาก เช่น 60-180` };
              }
            }
          } else if (arr.length > 2) {
            error = { Message: 'ใส่ข้อมูลให้ถูกต้อง เช่น 1-60' };
          }
        } else if (!+control.value) {
          error = { Message: 'ใส่ข้อมูลให้ถูกต้อง' };
        } else {
          const number = +control.value;
          if (!max) {
            if (!(number >= min)) {
              error = { Message: `ใส่ตัวเลขให้มากกว่า ${min}` };
            }
          } else {
            if (!(number >= min && number <= max)) {
              error = { Message: `ใส่ตัวเลขระหว่าง ${min}-${max}` };
            }
          }
        }
      }
      return error;
    };
  }

  get isBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }

  setURLToGo(url: any) {
    this.urlTogo = url;
  }

  get getUrlTogo(): any {
    return this.urlTogo;
  }

  languageUse(key: 'lc' | 'en') {
    localStorage.setItem('language', key);
    window.location.reload()
  }
}
