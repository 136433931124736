import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ModelUsers } from '../../../models/model-user.interface';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  ModalPageName = 'login';

  constructor(private http: HttpClient) {}

  signIn(result) {
    return this.http.post<ModelUsers>('authen/signin', result);
  }

  requestVerify(items) {
    return this.http.post<any>('authen/request_verify', items);
  }

  sendOTP(items) {
    return this.http.post<ModelUsers>('authen/verifyOTP', items);
  }

  OTPagain(token) {
    return this.http.get<any>(`authen/send_otp?token=${token}`);
  }

  checkemail(token) {
    return this.http.get<ModelUsers>(`authen/verify_email?token=${token}`);
  }

  setModalPageName(index) {
    if (index === 0) {
      this.ModalPageName = 'login';
    } else {
      this.ModalPageName = 'register';
    }
  }
  getModelPageName() {
    return this.ModalPageName;
  }

  forgot_password(items) {
    return this.http.post<any>('authen/forgot_password', items);
  }

  verifyToken(token: string) {
    return this.http.get(`authen/verify_token?token=${token}`);
  }

  reset_password(items) {
    return this.http.put<any>('authen/reset_password', items);
  }

  sendVerifyOTPForgetPassword(item) {
    return this.http.post<any>('authen/forgot_password/verify_otp', item);
  }

  sendResetPasswordForTel(item) {
    return this.http.put<any>('authen/reset_password', item);
  }

  OTPagainForgetPassword(token) {
    return this.http.get<any>(`authen/forgot_password/send_otp?token=${token}`);
  }
}
