import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  winner = new BehaviorSubject<IAuction>(null);
  loser = new BehaviorSubject<IAuction>(null);

  constructor() {}

  setWinner(model: IAuction) {
    this.winner.next(model);
  }

  setLoser(model: IAuction) {
    this.loser.next(model);
  }
}
