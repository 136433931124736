import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { AppModule } from './app.module';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    AppModule,
    BrowserTransferStateModule,
  ],
  bootstrap: [AppComponent]
})
export class AppBrowserModule { }
