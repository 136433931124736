import { Directive, HostBinding, Input, OnChanges } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

@Directive({
  selector: '[appValidate]'
})

export class ValidateDirective implements OnChanges {
    
    @Input('appValidate') control: AbstractControl;
    @HostBinding('class') calss: string = 'invalid-feedback';
    @HostBinding('innerText') text: string;

    errorMessage = {
        loginuser: "กรุณากรอกข้อมูลเป็น เบอร์โทร หรือ อีเมล",
    }
    // minlang , maxlang. min . max. pattern, custom massege

    constructor() { }

    /** จะทำงานทุกครั้งที่มีการกรอกข้อมูล input ที่มีการ validate */
    ngOnChanges() {
        if (!this.control) return;
        this.text = this.getErrorMessage();
        this.control.valueChanges.subscribe(() => {
            setTimeout(() => {
                this.text = this.getErrorMessage();
            }, 10);
        });
    }

    /** กำหนดข้อความ error ซึ่งจะแบ่งแยกรูปแบบการส่งโดยการใช้ if else */
    private getErrorMessage() {
        if(this.control) {
            const control = this.control;
            if (control && control.invalid) {
                const errorKey = Object.keys(control.errors)[0];
                let message = this.errorMessage[errorKey];    
                // console.log(message);
                return message;
            }
        }
    }
}