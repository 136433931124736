import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  constructor(private http: HttpClient) {}

  checkToken(token) {
    return this.http.get(`authen/valid_token?token=${token}`);
  }

  signOut(token) {
    return this.http.get(`authen/signout?token=${token}`);
  }
}
