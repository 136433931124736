import { Pipe, PipeTransform } from '@angular/core';
import json from './language.json';
@Pipe({
  name: 'language'
})
export class LanguagePipe implements PipeTransform {

  transform(value?: any): string {
    let language = localStorage.getItem('language');
    if (!language) { language = 'lc' }
    if (!value) {
      return value;
    } else {
      if (json[value]) {
        if (json[value][language] && json[value][language].length > 0) {
          return json[value][language];
        } else {
          if (json[value]['lc'] && json[value]['lc'].length > 0) {
            return json[value]['lc'];
          } else {
            return 'ไม่พบข้อความ';
          }
        }
      } else {
        return 'ยังไม่มีข้อมูล';
      }
    }
  }

}
