import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule, NgbRatingModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NgbModule,
    NgbRatingModule,
  ],
  exports: [
    NgbModule,
    NgbRatingModule,
  ],
})
export class NgBootstrapModule { }
