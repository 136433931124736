import { Directive, ElementRef, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Directive({
  selector: '[kfaShowPass]'
})
export class ShowPassDirective {
  hideEye: boolean;
  eyeEl: HTMLElement;
  constructor(private el: ElementRef, @Inject(DOCUMENT) private document: Document) {
    this.setupElemt();
  }
  setupElemt() {
    this.eyeEl = this.document.createElement('span');
    this.eyeEl.classList.value = 'el-password fas fa-eye';
    this.eyeEl.addEventListener('click', () => {
      const parentNode = (this.el.nativeElement as HTMLInputElement).parentNode as HTMLElement;
      const input = parentNode.querySelector('input');
      if (this.hideEye) {
        this.hideEye = false;
        const hideEl = parentNode.querySelector('.fa-eye-slash');
        hideEl.classList.value = 'el-password fas fa-eye';
        input.setAttribute('type', 'password');
      } else {
        this.hideEye = true;
        const showEl = parentNode.querySelector('.fa-eye');
        showEl.classList.value = 'el-password fas fa-eye-slash';
        input.setAttribute('type', 'text');
      }
    });
    const parent = (this.el.nativeElement as HTMLInputElement).parentNode as HTMLElement;
    parent.append(this.eyeEl);
  }
}
