<div class="mt-5 pt-5">
  <div class="bg-white" style="border-radius: 20px;border: solid 10px rgb(77, 77, 77);">
    <div class="position-relative pt-3 d-flex justify-content-center">
      <div class="absolute">
        <img width="100%" height="100%" src="../../../../assets/images/logo/loss.png" alt="" />
      </div>

      <div class="container-fluid">
        <div class="row mt-5">
          <div class="col-12 text-center">
            <h4 style="color: rgb(100, 100, 100);font-weight: bold;">ขอแสดงความเสียใจด้วย! คุณแพ้การประมูล</h4>
          </div>
          <div class="col-5 text-center">
            <img
              *ngIf="AuctionLoss?.AuctionMedias"
              width="100%"
              height="100%"
              [src]="content + AuctionLoss.AuctionMedias[0].MedPath"
              alt=""
            />
          </div>
          <div class="col-7 d-flex justify-content-start">
            <div class="">
              <h4 style="color: #000;font-weight: bold;">{{ AuctionLoss.AucName }}</h4>
              <p>
                ราคา :
                <span style="color: rgb(100, 100, 100);">{{ AuctionLoss.WinnerAuctionPrice | number }} THB</span>
              </p>
              <p>
                ผู้ชนะการประมูล :
                <ng-container *ngIf="AuctionLoss.WinnerCustomerId === AuctionLoss.OwnerId; else OtherWin">
                  <span style="color: rgb(100, 100, 100)">
                    {{ AuctionLoss.WinnerCustomerUsername }}
                  </span>
                </ng-container>
                <ng-template #OtherWin>
                  <span style="color: rgb(100, 100, 100)">
                    {{ AuctionLoss.WinnerCustomerUsername }}
                  </span>
                </ng-template>
              </p>
              <p>
                สิ้นสุดการประมูล :
                <span style="color: rgb(100, 100, 100)">{{ AuctionLoss.EndDt | date: 'dd/MM/yyyy HH:mm' }}</span>
              </p>
            </div>
          </div>
          <div class="col-12 mt-4 mb-2">
            <div class="d-block d-lg-flex text-center justify-content-lg-center">
              <a [routerLink]="['', 'auctions']" (click)="closeModal()">
                <button class="btn btn-outline-secondary px-5 mx-3 mb-3">ประมูลต่อ</button>
              </a>
              <a (click)="closeModal()">
                <button class="btn btn-outline-secondary px-5 mx-3 mb-3">ปิดหน้าต่าง</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<audio #audioOption>
  <source src="../../../../assets/sound/soundLoss.mp3" type="audio/mp3" />
</audio>
