import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AppService } from '../app.service';

@Injectable({
  providedIn: 'root'
})
export class LoadScriptsService {
  private added: boolean;

  constructor(@Inject(DOCUMENT) private document: Document, private svApp: AppService) {}

  loadScripts() {
    return new Promise<any>(resolve => {
      setTimeout(() => {
        if (!this.added) {
          const node = this.document.createElement('script');
          node.src = `${this.svApp.apiHost}signalr/hubs`;
          node.type = 'text/javascript';
          this.document.getElementsByTagName('body')[0].appendChild(node);
          resolve({ message: 'script loaded' });
          this.added = true;
        } else {
          resolve({ message: 'script already loaded' });
        }
      });
    });
  }
}
