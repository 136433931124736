export const AppURL = {
    advanceSearch: 'search',
    productDetail: 'product',
    sale: 'sale',
    auctionDetail: 'auction',
    auctions: 'auctions',
    shop: 'shop',
    about: 'about',
    account: 'account',
    management: 'managements',
    packages: 'packages',
    notification: 'notification',
};