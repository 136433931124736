<div class="d-flex flex-column vh-100">
  <div class="" style="box-shadow: 0px 2px 15px rgb(33 33 52 / 10%);z-index: 2;">
    <best-navbar #navbarComponent></best-navbar>
  </div>

  <div class="flex-grow-1" id="compo-public">
    <router-outlet></router-outlet>
  </div>

  <div class="">
    <ng-container *ngIf="!unshowUserMode">
      <!-- <best-footer></best-footer> -->
    </ng-container>
  </div>
</div>

<ng-template #modalAuctionDetail>
  <kfa-modal-auction-detail></kfa-modal-auction-detail>
</ng-template>

<kfa-admin-messager></kfa-admin-messager>