export namespace AuctionPageFilterNamespace {

    export class FilterClass {
        statusAuction: 'all' | 'soon' | 'act' | 'clo' = 'all';
        pageAuction: number = 1;
        limitAuction: number = 8;
        filterAuction?: IAdvanceFilter = new AdvanceFilterClass();
    }

    export interface IAdvanceFilter {
        PriceASC: boolean;
        SellingDateASC: boolean;
        IsSecondHand: boolean;
        CategoryId: number;
        BrandId: number;
        ModelId: number;
        TypeVehicleId: number;
        FuelId: number;
        ColorId: number;
        GearId: number;
        FromYear: number;
        ToYear: number;
        FromMileCount: number;
        ToMileCount: number;
        FromPrice: number;
        ToPrice: number;
        FromSellingDate: string;
        ToSellingDate: string;
        Provinces: IProvince[];
    }

    export interface IProvince {
        ProvinceId: number;
    }

    export interface IFormFilter {
        category: string;
        fuel: string;
        gear: string;
        color: string;
        province: number[];
        year: IYear;
        price: IYear;
        CategoryId: string;
        brand: string;
    }

    export interface IYear {
        min: string;
        max: string;
    }

    export class AdvanceFilterClass implements IAdvanceFilter {
        PriceASC: boolean;
        SellingDateASC: boolean;
        IsSecondHand: boolean;
        CategoryId: number;
        BrandId: number;
        ModelId: number;
        TypeVehicleId: number;
        FuelId: number;
        ColorId: number;
        GearId: number;
        FromYear: number;
        ToYear: number;
        FromMileCount: number;
        ToMileCount: number;
        FromPrice: number;
        ToPrice: number;
        FromSellingDate: string;
        ToSellingDate: string;
        Provinces: IProvince[];
    }
}

export namespace AuctionPageNamespace {
    export interface IResponse {
        items: IActionItem[];
        currentPage: number;
        limitRow: number;
        resultRow: number;
    }

    export interface IActionItem {
        ProductInfomationsId: number;
        ProductType: string;
        ProductId: number;
        IsSecondHand?: boolean;
        AreaDistrictId: number;
        DistrictName: string;
        ProvinceName: string;
        ModelId: number;
        ModelName: string;
        BrandName: string;
        TypeVehicleId: number;
        TypeVehicleName: string;
        CategoryCarId: number;
        CategoryCarName: string;
        MileCount: number;
        ProductionYear: string;
        ProductGearId: number;
        ProductGearName: string;
        ProductFuelId: number;
        ProductFuelName: string;
        Colors?: Color[];
        Attributes: Attribute[];
        AucId: number;
        AucCode: string;
        OwnerId: number;
        OwnerFullName: string;
        OwnerUsername: string;
        OwnerEmail: string;
        OwnerPhoneNo: string;
        WinnerCustomerId?: any;
        WinnerCustomerUsername?: any;
        WinnerProfileImg?: any;
        WinnerAuctionPrice?: any;
        AucTypeId: number;
        TypeName: string;
        TypeDetail: string;
        AucName: string;
        AucDetail: string;
        StartPrice: number;
        MinimumBid: number;
        BotName?: string;
        BotDelay?: any;
        BotBidPrice?: string;
        SalePrice: number;
        SalePriceDisplay: boolean;
        StartDt: string;
        EndDt: string;
        AccessLevel: string;
        Status: string;
        ShowHallOfFame: boolean;
        CreateDt: string;
        UpdateDt?: any;
        PreviewImage: PreviewImage[];
        AuctionMedias: PreviewImage[];
        InvIdOwner?: any;
        InvPaymentSlipOwner?: any;
        InvPaymentDetailOwner?: any;
        InvStatusOwner?: any;
        InvIdWinner?: any;
        InvStatusWinner?: any;
        CustomerAddress?: any;
        AuctionTracking?: any;
        LobbyMembers?: any;
        OwnerVerified: string;
        MoreModelName: string;
    }

    export interface PreviewImage {
        AucMedId: number;
        AucId: number;
        PreImg: string;
        MedRank: string;
        MedPath: string;
        Status: string;
    }

    export interface Attribute {
        ProductAttributeId: number;
        AttributeName: string;
        AttributeValue: string;
    }

    export interface Color {
        ProductColorId: number;
        ProductColorName: string;
    }
}