export const YouManagementUrl = {
  profile: 'profile',
  dashboard: 'dashboard',
  auctions: 'auctions',
  auction: 'auction',
  payments: 'payments',
  favorite: 'favorite',
  invoice: 'invoice',
  verifyIdentiry: 'verify-identiry',
  settingAuction: 'setting-auction',
  manageUser: 'manage-user',
  profileShop: 'profile-shop',
  chatShop: 'chat-shop',
  settingVerifyUser: 'setting-verify-user',
  chagePassword: 'change-password',
  connectAnother: 'connect-another',
  order: 'order',
  product: 'product',
};
