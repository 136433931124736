import { KEY_TOKEN } from './../../../models/app.models';
import { ModalLoginComponent } from './../modal-login/modal-login.component';
import { LoginService } from './../../services/login/login.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ImageItem } from '@ngx-gallery/core';
import { map } from 'rxjs/operators';
import moment from 'moment';
import { FavoritService } from 'src/app/services/favorit.service';
import { AuctionPageNamespace } from 'src/app/interfaces/auction';
import { Router } from '@angular/router';

@Component({
  selector: 'kfa-modal-auction-detail',
  templateUrl: './modal-auction-detail.component.html',
  styleUrls: ['./modal-auction-detail.component.scss']
})
export class ModalAuctionDetailComponent implements OnInit, OnDestroy {
  content = environment.content;
  iconCheck = 'assets/images/icon/correct.png';


  modalRefLogin: BsModalRef;
  @ViewChild('loginModal', { static: true }) loginModal: TemplateRef<any>;
  customer: ICustomer;

  Item: AuctionPageNamespace.IActionItem & {
    IsFavorite: boolean;
    Images: any[];
    startSeconds: number;
    endSeconds: number;
  };
  Subscriptions: Subscription[] = [];

  constructor(
    private app: AppService,
    private favoriteService: FavoritService,
    private loginService: LoginService,
    private modalService: BsModalService,
    private router: Router,
    ) {
    this.Subscriptions.push(
      this.app.modalDetail
        .pipe(
          map(res => {
            if (res) {
              res.GalleryItems = [];
              res.Images = [];
              res.AuctionMedias.map(con => {
                if (con.MedRank === 'image') {
                  res.GalleryItems = [
                    ...res.GalleryItems,
                    new ImageItem({ src: `${this.content}${con.MedPath}`, thumb: `${this.content}${con.MedPath}` })
                  ];
                  res.Images.push({ ...con, MedPath: `${this.content}${con.MedPath}` });
                  // } else if (con.MedRank === 'video') {
                  //   res.GalleryItems = [
                  //     ...res.GalleryItems,
                  //     new VideoItem({ src: `${con.MedPath}`, thumb: `/assets/images/icon/play-button.png` })
                  //   ];
                }
              });
            }
            return res;
          })
        )
        .subscribe(res => {
          if (res) {
            this.app.updateDateTimeNow().then(() => {
              const now = this.app.dateTimeNow;
              res.startSeconds = moment(res.StartDt).diff(now, 'seconds');
              res.endSeconds = moment(res.EndDt).diff(now, 'seconds');
              res.endDays = moment(res.EndDt).diff(now, 'days');
              this.Item = res;
            });
          }
        })
    );
  }

  ngOnInit() { }

  ngOnDestroy() {
    this.app.modalDetail.next(null);
    this.Subscriptions.map(s => s.unsubscribe());
    this.Subscriptions = [];
  }

  closeModal() {
    this.app.modalDetail.next(null);
  }

  onCreateFavorite() {
    if (!localStorage.getItem(KEY_TOKEN)) {
      this.loginService.setModalPageName(0);
      this.modalRefLogin = this.modalService.show(this.loginModal, {
        class: 'modal-md transparent',
      });
      return;
    }
    this.favoriteService.CreateFavorit(this.Item.AucId).subscribe(res => {
      if (res) {
        this.Item.IsFavorite = true;
        this.favoriteService.favoriteSubscription.next({ aucId: this.Item.AucId, isFav: true });
      }
    });
  }

  onRemoveFavorite() {
    this.favoriteService.DeleteFavorit(this.Item.AucId).subscribe(res => {
      if (res) {
        this.Item.IsFavorite = false;
        this.favoriteService.favoriteSubscription.next({ aucId: this.Item.AucId, isFav: false });
      }
    });
  }

  /** โหลดข้อมูล ModalLoginComponent */
  onLoadModalLoginComponent(component: ModalLoginComponent) {
    if (!this.customer) return;
    const { CustomerStatus } = this.customer;
    if (CustomerStatus === 'dea') {
      this.modalRefLogin.hide();
      component.openModalVerify();
    }
  }

  nextPage() {
    if (!localStorage.getItem(KEY_TOKEN)) {
      this.loginService.setModalPageName(0);
      this.modalRefLogin = this.modalService.show(this.loginModal, {
        class: 'modal-md transparent',
      });
      return;
    } else {
      // [routerLink]="['', 'auction', Item.AucCode]
      this.router.navigate(['','auction', this.Item.AucCode]);
    }
  }

}
