import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  notiHub: any;
  notiItems$ = new BehaviorSubject<any>(null);
  iconNotify$ = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient) {}

  init (page: number, pagelimit: number, filter: string) {
    return this.notiHub.server.init(page, pagelimit, filter);
  }

  read (notiId: number) {
    return this.notiHub.server.read(notiId);
  }

  readAll () {
    return this.notiHub.server.readAll();
  }

  seen () {
    return this.notiHub.server.seen();
  }
}
