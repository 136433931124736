import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BestPaginationComponent } from './best-pagination.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    BestPaginationComponent
  ],
  imports: [
    CommonModule,
    PaginationModule,
    FormsModule
  ],
  exports: [
    BestPaginationComponent
  ]
})
export class BestPaginationModule { }
