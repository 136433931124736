import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';
import { Component, OnInit, OnDestroy, Inject, ChangeDetectorRef } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { interval, Subscription, timer } from 'rxjs';
import { InboxService } from 'src/app/services/inbox.service';
import { Inbox } from 'src/app/interfaces/inbox';
import { Reply } from 'src/app/interfaces/reply';
import { CustomerService } from 'src/app/services/customer.service';
import { FormControl, Validators } from '@angular/forms';
import { Logger } from 'src/app/utils/console';
import moment from 'moment';
import { Router } from '@angular/router';
import { AppURL } from 'src/app/app.url';
import { LanguagePipe } from 'src/app/shared/pipes/language.pipe';


@Component({
  selector: 'kfa-admin-messager',
  templateUrl: './admin-messager.component.html',
  styleUrls: ['./admin-messager.component.scss'],
  providers: [LanguagePipe]
})
export class AdminMessagerComponent implements OnInit, OnDestroy {
  content = environment.content;
  customer: ICustomer;
  noUserImg =
    'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT8Cuyq7AdKhhmAL6ceV-s8oLT630oo9uMOWPjH994GigCH5S3q';

  inboxs: Inbox[] = [];
  inboxActive: Inbox;
  replies: Reply[] = [];
  unreadCount = 0;

  
  read = this.languageSer.transform('read');

  inputForm = new FormControl('', [Validators.required, Validators.maxLength(100)]);
  showMessage = true;

  Subscriptions: Subscription[] = [];
  constructor(
    private svApp: AppService,
    private svInbox: InboxService,
    @Inject(DOCUMENT) private dom: Document,
    private svCustomer: CustomerService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private languageSer: LanguagePipe,
    ) { }

  ngOnInit() {
    this.activateSubscribe();
    if (this.svApp.isBrowser) {
      this.Subscriptions.push(this.onQuickMessageEvent());
      this.dom.getElementById('msg-box').addEventListener('scroll', (event: any) => {
        if (event.target.scrollTop === 0) {
          this.dom.getElementById('msg-bar').className = 'message-bar justify-content-center';
        } else {
          this.dom.getElementById('msg-bar').className = 'message-bar justify-content-center border-bottom-header';
        }
      });
    }
  }

  ngOnDestroy() {
    this.Subscriptions.map(S => S.unsubscribe());
    this.Subscriptions = [];
  }

  // ส่วนของ event ข้อความ quick messate items
  onQuickMessageEvent() {
    return this.svInbox.quickMessage$.subscribe(message => {
      this.svInbox.quickMessage = message;
      this.inputForm.setValue(message);
    });
  }

  activateSubscribe() {
    this.Subscriptions.push(
      this.svInbox.inbox$.subscribe(inb => {
        this.inboxs = inb || [];

        if (this.svApp.isBrowser) {
          setTimeout(() => {
            if (!this.cdr['destroyed']) {
              this.cdr.detectChanges();
            }
          }, 0);
        }

        if (this.inboxActive) {
          this.inboxActive = this.inboxs.find(inb => inb.Id === this.inboxActive.Id);
        }

        // นับที่ยังไม่อ่าน
        this.unreadCount = this.inboxs.reduce((prev, cur) => (prev += cur.Unread || 0), 0);
      }),
      this.svInbox.replies$.subscribe(rep => {
        this.replies = rep || [];
        this.replies.forEach( (item: any) => {
          if (item.MessageType === 'A') {
            let RefCode1;
            try {
              RefCode1 = JSON.parse(item.Message);
              item['MessageData'] = RefCode1;
            } catch (error) {
                // console.log('Error parsing JSON:', error, item.Message);
            }
            if (item.MessageData && item.MessageData.PreviewImage) {
              let RefCode;
              try {
                RefCode = JSON.parse(item.MessageData.PreviewImage);
                item.MessageData.PreviewImage = RefCode;
              } catch (error) {
                  // console.log('Error parsing JSON:', error, item.MessageData.PreviewImage);
              }
            }
          }
        });
        this.checkDayReplies();

        if (this.svApp.isBrowser) {
          setTimeout(() => {
            if (!this.cdr['destroyed']) {
              this.cdr.detectChanges();
            }
          }, 0);
        }
      }),
      this.svCustomer.Customer$.subscribe(customer => {
        this.customer = customer;

        if (!this.customer && this.inboxs.length) {
          this.svInbox.inbox$.next([]);
          this.svInbox.replies$.next([]);
          this.inboxActive = null;
        }
      }),
      this.svInbox.statusMessage$.subscribe(({ status, customerId }) => {
        if (status) {
          if (customerId) {
            this.setActMsger(this.inboxs.find(inb => inb.CustomerId === customerId));

            if (this.svApp.isBrowser) {
              setTimeout(() => {
                this.dom.getElementById('input-msg').focus();
                this.dom.getElementById('input-msg-mb').focus();
              }, 100);
            }
          }

          this.showMessageBox();
          this.openMobileBoxCont();
        } else {
          this.hideMessageBox();
        }
      }),
      this.svInbox.auctionChat$.subscribe(({ AucId }) => {
        if (AucId) {
          this.sendAuction(AucId);
        }
      })
    );
  }

  setActMsger(inb: Inbox) {
    // ออกจาก inbox นี้ก่อนเข้า inbox ใหม่
    if (!!this.inboxActive) {
      this.svInbox.leaveInbox(this.inboxActive.Id);
    }

    // ดึงข้อความทั้งหมดครั้งแรก
    this.svInbox.getReplies(inb.Id).done(rep => {
      this.replies = rep;
      this.replies.forEach( (item: any) => {
        if (item.MessageType === 'A' && item.Message) {
          let RefCode1;
          try {
            RefCode1 = JSON.parse(item.Message);
            item['MessageData'] = RefCode1;
          } catch (error) {
              // console.log('Error parsing JSON:', error, item.Message);
          }
          if (item.MessageData.PreviewImage) {
            let RefCode;
            try {
              RefCode = JSON.parse(item.MessageData.PreviewImage);
              item.MessageData.PreviewImage = RefCode;
            } catch (error) {
                // console.log('Error parsing JSON:', error, item.MessageData.PreviewImage);
            }
          }          
        }
      });
      // console.log("this.replies : ", this.replies);
      this.checkDayReplies();

      if (this.svApp.isBrowser) {
        setTimeout(() => {
          if (!this.cdr['destroyed']) {
            this.cdr.detectChanges();
          }
        }, 0);
      }

      this.inboxActive = inb;


      // ตรวจสอบว่ามีการกด Quick message หรือไม่?
      if ((this.svInbox.quickMessage + '').trim() != '') {
        this.sendReply();
        this.svInbox.quickMessage = null;
      }

      // บอกว่าอ่านแล้ว
      this.readReply();
    });
  }
  clearActMsger() {
    // ออกจาก inbox นี้ก่อนเข้า inbox ใหม่
    if (!!this.inboxActive) {
      this.svInbox.leaveInbox(this.inboxActive.Id);
    }
    this.inboxActive = null;
  }

  checkDayReplies() {
    this.replies.map((reply, index) => {
      if (index === 0) {
        // ครั้งแรกไม่เช็ควัน
        reply.newDay = true;
      } else {
        const msgDay1c = +moment(this.replies[index - 1].CreateAt).format('YYYYMMDD');
        const msgDayc = +moment(reply.CreateAt).format('YYYYMMDD');
        // เช็ควัน ไม่ใช่วันเดียวกัน
        if (msgDay1c < msgDayc) {
          reply.newDay = true;
        }
      }
    });

    this.replies.reverse().forEach((reply, index) => {
      if (index === 0) {
        // ครั้งแรกไม่เช็คเวลา
        reply.newTime = true;
      } else {
        const msgTime1c = +moment(this.replies[index - 1].CreateAt).format('YYYYMMDDHHmm');
        const msgTimec = +moment(reply.CreateAt).format('YYYYMMDDHHmm');
        const cusTime1c = this.replies[index - 1].CustomerSenderId;
        const cusTimec = reply.CustomerSenderId;
        // เช็คเวลา กับ ข้อความก่อนหน้าไม่ใช่อันเดียวกัน
        if (msgTime1c > msgTimec || cusTimec !== cusTime1c) {
          reply.newTime = true;
        }
      }
    });
    this.replies.reverse();

    this.scrollToBottom();
  }

  typesTextArea(event: any) {
    if (!event.shiftKey && event.keyCode === 13) {
      event.preventDefault();
      this.sendReply();
      this.readReply();
    }
  }

  sendReply() {
    if (this.inputForm.value && this.inputForm.value.trim()) {
      const value = { InboxId: this.inboxActive.Id, Message: this.inputForm.value.trim() };
      this.inputForm.disable();
      this.svInbox
        .reply(value)
        .done(() => {
          // Logger('reply done.');
          this.inputForm.setValue('');
          this.svInbox.quickMessage = null;
          this.inputForm.enable();
        })
        .fail(err => {
          // Logger(err);
          this.inputForm.enable();
        });
    }
  }

  /** บอกว่ากำลังคุยเรื่องสินค้านี้นะ */
  sendAuction(AucId: number) {
    if (this.inboxActive.LastMessage === "Attach") { return; }
    const value = { InboxId: this.inboxActive.Id, Message: AucId+'', MessageType: 'A' };
    this.inputForm.disable();
    this.svInbox
      .reply(value)
      .done(() => {
        // Logger('reply done.');
        this.inputForm.setValue('');
        this.svInbox.quickMessage = null;
        this.inputForm.enable();
      })
      .fail(err => {
        // Logger(err);
        this.inputForm.enable();
      });
  }

  focusTextArea() {
    this.readReply();
  }

  readReply() {
    if (
      this.replies.length &&
      this.customer.CustomerId !== this.replies[this.replies.length - 1].CustomerSenderId &&
      !this.replies[this.replies.length - 1].Read
    ) {
      this.svInbox
        .read(this.inboxActive.Id)
        .done(() => {
          // Logger('read done.');
        })
        .fail(err => {
          // Logger(err);
        });
    }
  }

  scrollToBottom() {
    if (this.svApp.isBrowser) {
      setTimeout(() => {
        const msgEl = document.getElementById('scroll');
        if (msgEl) msgEl.scrollTop = msgEl.scrollHeight;
        const msgElmb = document.getElementById('scroll-mb');
        if (msgElmb) msgElmb.scrollTop = msgElmb.scrollHeight;
      }, 100);
    }
  }

  showMessageBox() {
    if (this.svApp.isBrowser) {
      const btnMsg = $('#messageBtn');
      const boxMsg = $('#messageContainer');
      // SHOW MESSAGE
      if (!boxMsg.hasClass('d-none')) return;
      boxMsg.toggleClass(['slideOutDown', 'slideInUp', 'd-none', 'd-block']);
      btnMsg.toggleClass(['d-block', 'd-none']);
    }
  }

  hideMessageBox() {
    if (this.svApp.isBrowser) {
      const btnMsg = $('#messageBtn');
      const boxMsg = $('#messageContainer');
      const faster = 300; // animate duration with class .faster
      // HIDE MESSAGE
      if (!btnMsg.hasClass('d-none')) return;
      boxMsg.toggleClass(['slideInUp', 'slideOutDown']);
      btnMsg.toggleClass(['d-none', 'd-block']);
      setTimeout(() => {
        boxMsg.toggleClass(['d-block', 'd-none']);
      }, faster);
    }
  }

  toggleMessageBox() {
    if (this.svApp.isBrowser) {
      const btnMsg = $('#messageBtn');
      const boxMsg = $('#messageContainer');
      const faster = 300; // animate duration with class .faster
      if (btnMsg.hasClass('d-block')) {
        // SHOW MESSAGE
        boxMsg.toggleClass(['slideOutDown', 'slideInUp', 'd-none', 'd-block']);
        btnMsg.toggleClass(['d-block', 'd-none']);
      } else {
        // HIDE MESSAGE
        boxMsg.toggleClass(['slideInUp', 'slideOutDown']);
        btnMsg.toggleClass(['d-none', 'd-block']);
        setTimeout(() => {
          boxMsg.toggleClass(['d-block', 'd-none']);
        }, faster);
      }
    }
  }

  Login() {
    this.svApp.subject.next({ statusLogin: true });
    this.hideMessageBox();
  }

  Signup() {
    this.svApp.subject.next({ statusLogin: false });
    this.hideMessageBox();
  }

  // Mobile Screen
  openMobileBoxCont() {
    if (!this.svApp.isBrowser) return;
    $('#mbsBox').addClass('fadeInUp show');
  }
  closeMobileBoxCont() {
    if (!this.svApp.isBrowser) return;
    $('#mbsBox').toggleClass('fadeInUp fadeOutDown');
    const faster = 300;
    setTimeout(() => {
      $('#mbsBox').removeClass('fadeOutDown show');
    }, faster);
  }

  nextAuction(MessageData: any) {
    location.href = ['', AppURL.auctionDetail  , MessageData.shopURL, MessageData.AucCode ].join(`/`);
  }
}
