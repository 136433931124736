import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { LocalStorageService } from '../core/services/local-storage.service';
import { IAddress } from '../models/model-address.interface';
import { ModelPayment } from '../models/model-userinfo.interface';
import { KEY_USER } from '../models/app.models';
import { IPayment } from '../interfaces/payment';
import { CustomerProviderIF } from 'src/app/interfaces/customer-provider';
import { IBank } from '../interfaces/bank';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  Customer$ = new BehaviorSubject<ICustomer>(null);
  updateCustomer$ = new Subject<boolean>();
  NavbarTab$ = new Subject<{name: string, value: any}>();

  constructor(private http: HttpClient, private localStorage: LocalStorageService) {}

  // #region Customer
  getCustomerInfo() {
    return this.http.get<ICustomer>('customer/info').pipe(
      map(customer => {
        this.localStorage.set(KEY_USER, customer);
        return customer;
      })
    );
  }
  getCustomerExceptInLobby({ page, limit, name, filter, auctionId }: IModelPager) {
    const pageParams = `page=${page}&limit=${limit}&name=${name}&filter=${filter}`;
    return this.http.get<IPagination<ICustomerLobby>>(`customer/except_in_auction/${auctionId}?${pageParams}`);
  }
  UpdateCustomerInfo(item) {
    return this.http.put('customer/update', item);
  }
  // #endregion Customer

  // #region ADDRESS
  getAddressById(addressId: number) {
    return this.http.get<IAddress[]>(`customers/addresses/${addressId}`);
  }
  getAllAddress() {
    return this.http.get<IAddress[]>('customers/addresses');
  }
  deleteAddressById(id: number) {
    return this.http.delete(`customers/addresses/${id}`);
  }
  createAddress(item: IAddress) {
    return this.http.post('customers/addresses', item);
  }
  updateAddress(item: IAddress, id: number) {
    return this.http.put(`customers/addresses/${id}`, item);
  }
  switchAddress(id) {
    return this.http.post(`customers/addresses/${id}/switch`, '');
  }
  defaultAddress(id) {
    return this.http.post(`customers/addresses/${id}/setdefault`, '');
  }
  // #endregion ADDRESS

  // #region SELLER
  register_Seller(item) {
    return this.http.put('customer/seller_register', item);
  }
  // #endregion SELLER

  // #region PAYMENT
  getListPayments() {
    return this.http.get<IPayment[]>('customers/payment_methods');
  }
  createPayment(item) {
    return this.http.post<ModelPayment>('customers/payment_methods', item);
  }
  updatePayment(item, id) {
    return this.http.put<ModelPayment>(`customers/payment_methods/${id}`, item);
  }
  deletePaymentById(id) {
    return this.http.delete(`customers/payment_methods/${id}`);
  }
  getListBanks() {
    return this.http.get<IBank[]>('banks');
  }
  // #endregion PAYMENT

  // #region OTHER
  changePhone(items) {
    return this.http.put<any>('customer/change_phoneNo', items);
  }
  verify_newPhoneNo(items) {
    return this.http.post('customer/verify_newPhoneNo', items);
  }
  changePassword(items) {
    return this.http.put('customer/change_password', items);
  }
  changeEmail(items) {
    return this.http.put('customer/change_email', items);
  }
  checkeTokenEmail(token) {
    return this.http.get(`customer/verify_newEmail?token=${token}`);
  }

  checkduplicate(username: any) {
    return this.http.get<any>(`customer/username/checkduplicate/${username}`);
  }
  // #endregion OTHER

  // #verify merchant
  get_verify_merchant() {
    return this.http.get('customer/merchant/verify');
  }

  verify_merchant(items) {
    return this.http.put('customer/merchant/verify', items);
  }
  // #endregion verify merchant

  /** login ผ่านทาง facebook */
  PostFacebook(AccessToken: string, DeviceName: string) {
    let output = {
      ProviderKey: '7098845393561022',
      AccessToken: AccessToken,
      DeviceName: DeviceName,
    }
    return this.http.post('authenprovider/signin/facebook', output);
  }

  /** login ผ่านทาง google */
  PostGoogle(AccessToken: string, DeviceName: string) {
    let output = {
      ProviderKey: '',
      AccessToken: AccessToken,
      DeviceName: DeviceName,
    }
    return this.http.post('authenprovider/signin/google', output);
  }

  /** login ผ่านทาง line */
  PostLine(AccessToken: string, DeviceName: string) {
    let output = {
      ProviderKey: '',
      AccessToken: AccessToken,
      DeviceName: DeviceName,
    }
    return this.http.post('authenprovider/signin/line', output);
  }

  /** ข้อมูลผู้ให้บริการทั้งหมด */
  getCustomerprovider() {
    return this.http.get<CustomerProviderIF[]>('customerprovider/all');
  }

  /** ข้อมูลผู้ให้บริการที่ผูกแล้วโดย Id */
  getCustomerproviderById(ProviderId: string) {
    return this.http.get(`customerprovider/${ProviderId}`);
  }

  /** ผูกบัญชีกับผู้ให้บริการ */
  postCustomerproviderBind(ProviderType: string, ProviderKey: string, AccessToken: string, DeviceName: string) {
    let output = {
      ProviderType: ProviderType, 
      ProviderKey: ProviderKey, 
      AccessToken: AccessToken, 
      DeviceName: DeviceName
    }
    return this.http.post(`customerprovider/bind`, output);
  }

  /** ยกเลิกผูกบัญชีกับผู้ให้บริการ */
  getCustomerproviderUnBind(ProviderId: string) {
    return this.http.delete(`customerprovider/unbind/${ProviderId}`);
  }

  /** ขอเปลี่ยน เบอร์โทรศัพท์หรืออีเมล */
  postChangeContact(RequestType: string, VerifyByType: string ) {
    let output = {
      RequestType: RequestType,
      VerifyByType: VerifyByType,
    }
    return this.http.post<{RefCode: string, SecretKey: string}>(`customer/changecontact/request`, output);
  }

  /** ยืนยัน OTP ขอเปลี่ยน เบอร์โทรศัพท์หรืออีเมล */
  postOTPChangeContact(SecretKey: string, OTPCode: string ) {
    let output = {
      SecretKey: SecretKey,
      OTPCode: OTPCode,
    }
    return this.http.post(`customer/changecontact/request/verify`, output);
  }

  /** ใช้ Key ระบุเบอรโทรศัพท์หรืออีเมลใหม่ */
  postContactNew(output: any) {
    return this.http.post<{RefCode: string, SecretKey: string}>(`customer/changecontact/new`, output);
  }

  /** ยืนยัน OTP ใหม่ที่ขอเปลี่ยน และรับ Key */
  postOTPContactNew(SecretKey: string, OTPCode: string) {
    let output = {
      SecretKey: SecretKey,
      OTPCode: OTPCode,
    }
    return this.http.post(`customer/changecontact/new/verify`, output);
  }


}
