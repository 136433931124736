<div class="modal-body" *ngIf="Item">
  <div class="d-flex justify-content-between">
    <p class="modal-title pull-left">หัวข้อ: 
      <ng-container *ngIf="Item.OwnerVerified === 'ver'">
        &nbsp;&nbsp;
        <img [src]="iconCheck" alt="" class="image-check">
      </ng-container>  
      {{ Item.AucName }}
    </p>
    <div class="my-auto px-2">
      <a *ngIf="Item.IsFavorite" class="material-icons text-danger" style="font-size: 32px;" tooltip="สิ่งที่ชอบ"
        placement="left" (click)="onRemoveFavorite()">
        favorite
      </a>
      <a *ngIf="!Item.IsFavorite" class="material-icons text-danger" style="font-size: 32px;" tooltip="สิ่งที่ชอบ"
        placement="left" (click)="onCreateFavorite()">
        favorite_border
      </a>
    </div>
  </div>
  <div class="content mt-3">
    <div class="row">
      <div class="col"></div>
      <div class="col-12 col-lg-8">
        <kfa-images-show [items]="Item.Images"></kfa-images-show>
      </div>
      <div class="col"></div>
    </div>
    <!-- <gallery [items]="Item?.GalleryItems"></gallery> -->
    <div class="content-text">
      <table class="table table-borderless" style="table-layout: fixed; word-wrap: break-word;">
        <thead>
          <tr>
            <th width="170px"></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="text-right">หมวดหมู่ :</td>
            <td>{{ Item.TypeVehicleName }}</td>
          </tr>
          <tr>
            <td class="text-right">ยี่ห้อ-รุ่น :</td>
            <td>
              {{ Item.BrandName }}
              <span *ngIf="Item.MoreModelName">{{ Item.MoreModelName }}</span>
              <span *ngIf="!Item.MoreModelName">{{ Item.ModelName }}</span>
            </td>
          </tr>
          <tr>
            <td class="text-right">เกียร์ :</td>
            <td>
              {{ Item.ProductGearName }}
            </td>
          </tr>
          <tr>
            <td class="text-right">รายละเอียด :</td>
            <td>
              <pre class="text-detail">{{ Item.AucDetail }}</pre>
            </td>
          </tr>
          <tr>
            <td class="text-right">สินค้าอยู่ที่ :</td>
            <td>
              {{ Item.ProvinceName }},
              {{ Item.DistrictName }}
            </td>
          </tr>
          <tr>
            <td class="text-right vertical-middle">ราคาตอนนี้ :</td>
            <td class="price">
              <div *ngIf="Item.startSeconds > 0 && Item.endSeconds > 0">
                <span class="text-primary1">{{ Item.StartPrice || 0 | number }}</span> THB
              </div>
              <div *ngIf="Item.startSeconds <= 0 && Item.endSeconds > 0">
                <span class="text-primary1">{{ Item.WinnerAuctionPrice || Item.StartPrice || 0 | number }}</span> THB
              </div>
              <div *ngIf="Item.startSeconds <= 0 && Item.endSeconds <= 0">
                <span class="text-primary1">{{ Item.WinnerAuctionPrice || 0 | number }}</span> THB
              </div>
            </td>
          </tr>
          <tr>
            <td class="text-right">อัตราการเพิ่มราคา :</td>
            <td>
              <span class=""> {{ Item.MinimumBid | number }}</span> THB
            </td>
          </tr>
          <tr>
            <td class="text-right">เริ่ม :</td>
            <td>{{ Item.StartDt | date: 'dd/MM/yyyy HH:mm' }} UTC+7</td>
          </tr>
          <tr>
            <td class="text-right">ถึง :</td>
            <td>{{ Item.EndDt | date: 'dd/MM/yyyy HH:mm' }} UTC+7</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="d-flex justify-content-center">
      <a (click)="nextPage()" class="mr-3">
        <button type="button" class="btn btn-primary1" (click)="closeModal()">ไปที่หน้าประมูล</button>
      </a>
      <button type="button" class="btn btn-danger" (click)="closeModal()">ยกเลิก</button>
    </div>
  </div>
</div>

<ng-template #loginModal>
  <kfa-modal-login [modalLogin]="modalRefLogin" (onLoadModal)="onLoadModalLoginComponent($event)"></kfa-modal-login>
</ng-template>