<!-- <div class="text-center mb-4">
  <img height="80px;" src="../../../../assets/images/logo/logo_koifish.png" alt="" />
</div> -->

<div class="bg-white rounded">
  <!-- <div class="modal-header">
    <span [ngClass]="{ active: activeTab === tab.login }" (click)="setActiveTab(tab.login)">ลงชื่อเข้าใช้</span>
    <span [ngClass]="{ active: activeTab === tab.register }" (click)="setActiveTab(tab.register)">สมัครสมาชิก</span>
  </div> -->
  <div class="modal-body">

    <h3 class="header-title">
      <ng-template [ngIf]="activeTab === tab.login">ลงชื่อเข้าใช้</ng-template>
      <ng-template [ngIf]="activeTab === tab.register">สมัครสมาชิก</ng-template>
    </h3>

    <nav class="tab-buttons">
      <div class="mr-1" [ngClass]="{ active: activeTab === tab.login }" (click)="setActiveTab(tab.login)">
        ลงชื่อเข้าใช้
      </div>
      <div class="ml-1" [ngClass]="{ active: activeTab === tab.register }" (click)="setActiveTab(tab.register)">
        สมัครสมาชิก
      </div>
    </nav>

    <div class="content-login" [ngClass]="{ 'sr-only': activeTab === tab.register }">

      <form [formGroup]="formLogin" (submit)="submitLogIn()">
        <div class="form-group">
          <label for="lusername">อีเมล หรือ เบอร์โทรศัพท์</label>
          <input type="text" formControlName="username" class="form-control" id="lusername" placeholder="อีเมล หรือ เบอร์โทรศัพท์" />
          <div [appValidate]="formLogin.get('username')"></div>
        </div>

        <div class="form-group mb-2">
          <label for="lpassword">รหัสผ่าน</label>
          <input type="password" formControlName="password" class="form-control" id="lpassword"
            placeholder="รหัสผ่าน" />
        </div>

        <div class="form-group forgot-password mb-4">
          <a (click)="openChooseForgetPassword()">ลืมรหัสผ่านใช่หรือไม่?</a>
        </div>

        <div class="form-group">
          <button type="submit" class="btn btn-best-primary btn-block">
            เข้าสู่ระบบ
          </button>
        </div>

        <div class="link-account">
          ยังไม่มีบัญชีใช่หรือไม่?
          <a href="javascript:;" (click)="activeTab =  tab.register">
            สมัครสมาชิก
          </a>
        </div>
      </form>
    </div>

    <div class="content-register" [ngClass]="{ 'sr-only': activeTab === tab.login }">
      <form [formGroup]="fromRegister" (submit)="onRegister()">

        <div class="form-group">
          <label for="rusername">ชื่อผู้ใช้</label>
          <input type="text" formControlName="CustomerUsername" id="rusername" maxlength="15" class="form-control"
            placeholder="ชื่อผู้ใช้งาน *ความยาวไม่เกิน 15 ตัวอักษร" />
        </div>

        <div class="form-group">
          <label for="remail">อีเมล</label>
          <input type="email" formControlName="CustomerEmail" class="form-control" placeholder="อีเมล" id="remail" />
        </div>

        <div class="form-group">
          <label for="">เบอร์โทรศัพท์ (ถ้ามี)</label>
          <ngx-intl-tel-input cssClass="custome-input-phone" [preferredCountries]="preferredCountries"
            [enableAutoCountrySelect]="false" [enablePlaceholder]="true" [searchCountryFlag]="true"
            [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
            [selectedCountryISO]="CountryISO.Thailand" [maxLength]="15" [tooltipField]="TooltipLabel.Name"
            [phoneValidation]="true" [separateDialCode]="separateDialCode" name="phone" formControlName="phone"
            [ngClass]="{ 'ng-invalid': fromRegister.invalid }">
          </ngx-intl-tel-input>
        </div>

        <div class="form-group">
          <label for="rpassword">รหัสผ่าน</label>
          <div class="input-group">
            <input type="password" formControlName="CustomerPassword" class="form-control" placeholder="รหัสผ่าน"
              id="rpassword" kfaShowPass />
          </div>
        </div>

        <div class="form-group mb-4">
          <label for="rconfirm-password">ยืนยันรหัสผ่าน</label>
          <div class="input-group">
            <input type="password" formControlName="CustomerConfirmPassword" class="form-control"
              placeholder="ยืนยันรหัสผ่าน" id="rconfirm-password" kfaShowPass />
          </div>
        </div>

        <div class="form-group">
          <button type="submit" class="btn btn-best-primary btn-block">
            ลงทะเบียน
          </button>
        </div>

        <div class="link-account">
          มีบัญชีอยู่แล้วใช่หรือไม่?
          <a href="javascript:;" (click)="activeTab =  tab.login">
            เข้าสู่ระบบ
          </a>
        </div>
      </form>
    </div>
  </div>
</div>

<ng-template #verifyModal>
  <kfa-modal-verify [modalVerify]="modalRefVerify"> </kfa-modal-verify>
</ng-template>

<ng-template #ChooseForgetPasswordModal>
  <div class="py-3 px-2 bg-white mt-5" style="border-radius: 5px;position: relative;">
    <div class="closeModal">
      <a (click)="modalChooseForgetPassword.hide()">
        <i class="fa fa-times" style="font-size: 25px;" aria-hidden="true"></i>
      </a>
    </div>

    <h4 class="my-3 text-center">
      เลือกวิธีกู้คืนรหัสผ่าน
    </h4>

    <div class="p-4">
      <button class="mb-3 btn btn-block btn-best-primary" (click)="openEmailForgetPassword()">
        <i class="far fa-envelope"></i> Email
      </button>

      <button class="btn btn-block btn-best-primary" (click)="openTelForgetPassword()">
        <i class="fa fa-phone-alt"></i> เบอร์โทรศัพท์
      </button>
    </div>
  </div>
</ng-template>

<ng-template #RequestTelForgetPasswordModal>
  <div *ngIf="!sendTelRequest && !sendOTPRequest" class="py-3 px-2 bg-white mt-5"
    style="border-radius: 5px;position: relative;">
    <div class="closeModal">
      <a (click)="modalTelForgetPassword.hide()">
        <i class="fa fa-times" style="font-size: 25px;" aria-hidden="true"></i>
      </a>
    </div>
    <div class="row text-center">
      <h3 class="m-auto">ลืมรหัสผ่าน</h3>
    </div>
    <form [formGroup]="formRequestTelForgetPassword" (ngSubmit)="onTelRequestForgetPassword()">
      <div class="row px-2 my-4 py-0">
        <div class="col-md-12 mt-2">
          <div class="row">
            <div class="col-12 col-md-3  mt-1 mb-2 text-center">
              <p style="font-size: 20px;">เบอร์โทร</p>
            </div>
            <div class="col-12 col-md-9 input-group d-block">
              <ngx-intl-tel-input [cssClass]="'custome-input-phone'" [preferredCountries]="preferredCountries"
                [enableAutoCountrySelect]="false" [enablePlaceholder]="true" [searchCountryFlag]="true"
                [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
                [selectedCountryISO]="CountryISO.Thailand" [maxLength]="15" [tooltipField]="TooltipLabel.Name"
                [phoneValidation]="true" [separateDialCode]="separateDialCode" name="phone" formControlName="phone">
              </ngx-intl-tel-input>
            </div>
            <div class="offset-md-3 col-12 col-md-9 mt-3">
              <button type="submit" class="btn btn-submit btn-block" [disabled]="loading">
                ส่งคำขอรหัสผ่านใหม่
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div *ngIf="sendTelRequest && !sendOTPRequest" class="py-2 px-2 bg-white"
    style="border-radius: 5px;position: relative;">
    <div class="closeModal">
      <a (click)="modalTelForgetPassword.hide()">
        <i class="fa fa-times" style="font-size: 25px;" aria-hidden="true"></i>
      </a>
    </div>
    <div class="row text-center mt-3">
      <h3 class="m-auto">ยืนยัน OTP Code</h3>
    </div>
    <form class="mb-3 mt-3" [formGroup]="fromOTPResetPassword" (submit)="sendOTPVerify()">
      <div class="col-md-12 mt-2">
        <div class="row">
          <div class="col-md-4">
            <p style="font-size: 20px">Ref Code</p>
          </div>
          <div class="col-md-8 form-group">
            <input style="font-size: 20px;" class="form-control" formControlName="OTPRef" disabled type="text" />
          </div>
        </div>
      </div>

      <div class="col-md-12 mt-2">
        <div class="row">
          <div class="col-md-4">
            <p style="font-size: 20px">OTP Code</p>
          </div>
          <div class="col-md-8 form-group">
            <input style="font-size: 20px;" formControlName="OTPCode" class="form-control" type="text" />
          </div>
        </div>
      </div>

      <div class="col-md-12 mt-2">
        <div class="row">
          <div class="col-md-4">
            <p style="font-size: 20px">{{'time_left' | language}}</p>
          </div>
          <div class="col-md-4 form-group pt-0">
            <p style="font-size: 20px;">
              <countdown #countdown (event)="finishTimeOTP($event)" [config]="configTime">$!m!:$!s!</countdown>
            </p>
          </div>
          <div class="col-md-4 form-group pt-0">
            <a class="py-2 pt-4" style="font-size: 16px" (click)="OtpAgain()">
              <i class="fas fa-redo"></i> ส่งอีกครั้ง</a>
          </div>
        </div>
      </div>

      <div class="col-md-12 mt-2">
        <div class="row">
          <div class="col-md-4"></div>
          <div class="col-md-8 form-group">
            <button type="submit" class="btn btn-submit" [disabled]="loading">
              ยืนยัน OTP
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div *ngIf="sendTelRequest && sendOTPRequest" class="py-2 px-2 bg-white"
    style="border-radius: 5px;position: relative;">
    <div class="closeModal">
      <a (click)="modalTelForgetPassword.hide()">
        <i class="fa fa-times" style="font-size: 25px;" aria-hidden="true"></i>
      </a>
    </div>

    <div class="row text-center mt-3">
      <h3 class="m-auto">รีเซ็ตรหัสผ่านใหม่</h3>
    </div>
    <form class="mt-5 mb-3" [formGroup]="fromOTPResetPassword" (submit)="sendNewPasswordConfrim()">
      <div class="col-md-12 mt-2">
        <div class="row">
          <div class="col-md-4">
            <p style="font-size: 20px">รหัสผ่าน</p>
          </div>
          <div class="col-md-8 form-group">
            <input style="font-size: 20px;" class="form-control" formControlName="NewPassword" type="password"
              kfaShowPass />
          </div>
        </div>
      </div>

      <div class="col-md-12 mt-2">
        <div class="row">
          <div class="col-md-4">
            <p style="font-size: 20px">รหัสผ่านอีกครั้ง</p>
          </div>
          <div class="col-md-8 form-group">
            <input style="font-size: 20px;" formControlName="ConfirmPassword" class="form-control" type="password"
              kfaShowPass />
          </div>
        </div>
      </div>

      <div class="col-md-12 mt-2">
        <div class="row">
          <div class="col-md-4"></div>
          <div class="col-md-8 form-group">
            <button type="submit" class="btn btn-submit" [disabled]="loading">
              ยืนยันรหัสผ่านใหม่
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #RequestEmailForgetPasswordModal>
  <div *ngIf="sendEmailRequest === false" class="py-3 px-2 bg-white mt-5"
    style="border-radius: 5px;position: relative;">
    <div class="closeModal">
      <a (click)="modalEmailForgetPassword.hide()">
        <i class="fa fa-times" style="font-size: 25px;" aria-hidden="true"></i>
      </a>
    </div>
    <div class="row text-center">
      <h3 class="m-auto">ลืมรหัสผ่าน</h3>
    </div>
    <form [formGroup]="formRequestEmailForgetPassword" (ngSubmit)="onEmailRequestForgetPassword()">
      <div class="row px-2 my-4 py-0">
        <div class="col-md-12 mt-2">
          <div class="row">
            <div class="col-12 col-md-3 mt-1 mb-2 text-center">
              <p style="font-size: 20px;">อีเมล</p>
            </div>
            <div class="col-12 col-md-9 form-group">
              <input class="form-control" style="padding: 10px; border-radius: 5px;" formControlName="Value"
                type="email" placeholder="example@mail.com" />
            </div>

            <div class="offset-md-3 col-12 col-md-9 ">
              <button type="submit" class="btn btn-submit btn-block" [disabled]="loading">
                ส่งคำขอรหัสผ่านใหม่
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div *ngIf="sendEmailRequest === true" class="py-2 px-2 bg-white" style="border-radius: 5px;position: relative;">
    <div class="closeModal">
      <a (click)="modalEmailForgetPassword.hide()">
        <i class="fa fa-times" style="font-size: 25px;" aria-hidden="true"></i>
      </a>
    </div>
    <div class="row px-2 mt-5 my-4 py-0">
      <div class="col-md-12 mt-2">
        <div class="form-group mt-3 text-center">
          <h5 style="color: #d42429">
            ระบบได้ส่งลิ้งเปลี่ยนรหัสผ่านไปยัง Email ของท่านเเล้ว
          </h5>
          <h5 style="color: #d42429">{{'please_check_inbox_junk_box' | language}}</h5>
        </div>

        <div class="form-group mt-3">
          <button (click)="modalEmailForgetPassword.hide()" type="button" class="btn btn-submit btn-block my-2">
            ปิดหน้าต่าง
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>