import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { LocalStorageService } from '../core/services/local-storage.service';
import { AuthenticationService } from '../core/services/authentication.service';
import { CustomerService } from 'src/app/services/customer.service';
import { KEY_TOKEN } from '../models/app.models';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private storage: LocalStorageService,
    private auth: AuthenticationService,
    private router: Router,
    private svCustomer: CustomerService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise<boolean>((resolve, reject) => {
      if (!this.storage.get(KEY_TOKEN)) {
        this.clearAuth();
        return resolve(false);
      }
      this.auth.checkToken(this.storage.get(KEY_TOKEN)).subscribe(
        res => {
          if (!res) {
            this.clearAuth();
            resolve(false);
          } else {
            this.svCustomer.updateCustomer$.next();
            resolve(true);
          }
        },
        _ => {
          this.clearAuth();
          resolve(false);
        }
      );
    });
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(next, state);
  }

  clearAuth() {
    // return;
    this.storage.clear();
    this.router.navigate(['']);
    this.svCustomer.Customer$.next(null);
    // window.open('https://dev.dee.auction', '_self');
  }
}
