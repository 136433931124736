<div class="container-fluid nav-alert-verify" [ngClass]="{ 'd-none': verifyStore }">
    <div class="d-flex-verify" [ngClass]="{'tab-hidden': tabHidden === true, 'tab-show': tabHidden === false}">
        <i class="fas fa-exclamation-circle tag-a h24 overflow-hidden" (click)="hiddenTab(false)"></i>
        <p class="ml-3 d-none d-md-block h24 overflow-hidden">{{ 'please_verify_buy_packages' | language}}</p>
        <a class="ml-2 h24 overflow-hidden" (click)="nextPage('verifyStore')"> {{ 'shop_verify' | language}}</a>

        <a class="ml-auto" (click)="hiddenTab(true)">{{'hide' | language}}</a>
    </div>
</div>

<div class="container-fluid nav-alert-verify" [ngClass]="{ 'd-none': packageStore }">
    <div class="d-flex-verify" [ngClass]="{'tab-hidden': tabHidden === true, 'tab-show': tabHidden === false}">
        <i class="fas fa-exclamation-circle tag-a h24 overflow-hidden" (click)="hiddenTab(false)"></i>
        <p class="ml-3 d-none d-md-block h24 overflow-hidden">{{'choose_packages' | language}}</p>
        <a class="ml-2 h24 overflow-hidden" (click)="nextPage('packageStore')">{{'buy_packages' | language}}</a>

        <a class="ml-auto" (click)="hiddenTab(true)">{{'hide' | language}}</a>
    </div>
</div>

<nav class="navbar navbar-expand-lg menu-navbar" [ngClass]="{'set-height-navuse': unshowUserMode}">
    <div class="container-fluid">
        <a class="navbar-brand" (click)="logoNext()">
            <div class="contatiner-set-img">
                <img src="./assets/svg/Logo_DeeAction.svg" alt="logo deeauction" class="logo">
            </div>
            <div class="compo-name-nav">
                {{ 'merchant' | language}}
            </div>
        </a>

        <div class="position-relative notify-mobile" style="width: 0px;height: 0px;" *ngIf="customer">
            <div class="position-absolute d-flex position-login">
                <div class="onpage-notify" style="margin-right: unset;transform: translateY(-8px);" [ngClass]="{'d-none': !onNotify}">
                    <div class="onpage-compo-i">
                        <i class="far fa-bell"></i>
                    </div>
                </div>
    
                <div class="my-notify" [ngClass]="{'d-none': onNotify}">
                    <a data-toggle="dropdown" style="position: relative;" (click)="openpopUp()">
                        <i class="far fa-bell"></i>
                        <div class="compo-bugget" *ngIf="notiData && notiData.UnSeen > 0" >
                            <div class="bugget" [ngClass]="{'min10': notiData.UnSeen < 10 }">
                                <span>{{ notiData.UnSeen < 100 ? notiData.UnSeen : '+99' }}</span>
                            </div>
                        </div>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" style="min-width: 230px;">
                        <ng-container *ngIf="notiData && notiData.items" >
                            <ng-container *ngFor="let notiItem of notiItems; let index = index">
                                <a class="dropdown-item " [ngClass]="{'unread-item': !notiItem.Read }" (click)="nextPageNotify(index)">
                                    <div class="component-notify">
                                        <div class="component-icon">
                                            <div class="icon-notify" [ngClass]="{ 'success': notifyCheck('color-success', notiItem.Type), 'error': notifyCheck('color-error', notiItem.Type)}">
                                                <i class="fas fa-shield-alt"  *ngIf="notifyCheck('icon-verify', notiItem.Type)"></i>
                                                <i class="fas fa-user" *ngIf="notifyCheck('icon-joinAuction', notiItem.Type)"></i>
                                                <i class="fas fa-gavel" *ngIf="notifyCheck('icon-auction', notiItem.Type)"></i>
                                                <i class="fas fa-store" *ngIf="notifyCheck('icon-store', notiItem.Type)"></i>
                                                <i class="far fa-credit-card" *ngIf="notifyCheck('icon-card', notiItem.Type)"></i>
                                            </div>
                                        </div>
                                        <div class="component-detail">
                                            <div class="text-detail">
                                                <span class="word-break-all" *ngIf="notiItem.Type === 'RV'">
                                                    <strong>{{ notiItem.FromName }}</strong> {{'send_bidder_verification_request_auction' | language}} <strong>{{ notiItem.ToName }}</strong>
                                                </span>
                                                <span class="word-break-all" *ngIf="notiItem.Type === 'RVS'">
                                                    {{'bidder_verification_request_auction' | language}} <strong>{{ notiItem.ToName }}</strong> <strong class="text-success"> {{'approved_success' | language}}</strong>
                                                </span>
                                                <span class="word-break-all" *ngIf="notiItem.Type === 'RVF'">
                                                    {{'bidder_verification_request_auction' | language}} <strong>{{ notiItem.ToName }}</strong> <strong class="text-danger"> {{'rejected' | language}}</strong>
                                                </span>
                                                <span class="word-break-all" *ngIf="notiItem.Type === 'RB'">
                                                    <strong>{{ notiItem.FromName }} </strong> {{'request_join_auction_item' | language}} <strong> {{ notiItem.ToName }}</strong>
                                                </span>
                                                <span class="word-break-all" *ngIf="notiItem.Type === 'RBS'">
                                                    {{'request_join2' | language}} <strong>{{ notiItem.ToName }}</strong> <strong class="text-success"> {{'approved_success' | language}}</strong>
                                                </span>
                                                <span class="word-break-all" *ngIf="notiItem.Type === 'RBF'">
                                                    {{'request_join2' | language}} <strong>{{ notiItem.ToName }}</strong> <strong class="text-danger"> {{'rejected' | language}}</strong>
                                                </span>
                                                <span class="word-break-all" *ngIf="notiItem.Type === 'AS'">
                                                    {{'list' | language}} <strong>{{ notiItem.ToName }}</strong> {{'start_now' | language}}
                                                </span>
                                                <span class="word-break-all" *ngIf="notiItem.Type === 'AW'">
                                                    {{'list' | language}} <strong>{{ notiItem.ToName }}</strong> <strong class="text-success"> {{'you_winner' | language}}</strong>
                                                </span>
                                                <span class="word-break-all" *ngIf="notiItem.Type === 'AL'">
                                                    {{'list' | language}} <strong>{{ notiItem.ToName }}</strong> <strong class="text-danger"> {{'you_loser' | language}}</strong>
                                                </span>
                                                <span class="word-break-all" *ngIf="notiItem.Type === 'MVR'">
                                                    <strong>{{ notiItem.ToName }}</strong> {{'your_shop_sent_verification_request' | language}}
                                                </span>
                                                <span class="word-break-all" *ngIf="notiItem.Type === 'MVS'">
                                                    {{'shop_identity_verification_request' | language}} <strong>{{ notiItem.ToName }}</strong> <strong class="text-success"> {{'approved_success' | language}}</strong>
                                                </span>
                                                <span class="word-break-all" *ngIf="notiItem.Type === 'MVF'">
                                                    {{'shop_identity_verification_request' | language}} <strong>{{ notiItem.ToName }}</strong> <strong class="text-danger"> {{'rejected' | language}}</strong>
                                                </span>
                                                <span class="word-break-all" *ngIf="notiItem.Type === 'SP'">
                                                    {{'package_payment_completed' | language}}
                                                </span>
                                                <span class="word-break-all" *ngIf="notiItem.Type === 'SA'">
                                                    {{'received_package_admin' | language}}
                                                </span>
                                            </div>
                                            <p class="text-time" *ngIf="notiItem?.settime">{{ notiItem?.settime }}</p>
                                        </div>
                                    </div>
                                </a>
                            </ng-container>
                        </ng-container>
                        <a class="dropdown-item see-all unread-item"
                          *ngIf="notiItems && notiItems.length > 0"
                          (click)="nextPageNotify(-1)">
                            <p class="text-center">{{'more_all' | language}}</p>
                        </a>
                        <a class="dropdown-item see-all unread-item"
                          *ngIf="!notiItems || notiItems.length === 0">
                            <p class="text-center">{{'no_notifications' | language}}</p>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-expand-menu">
            <span class="navbar-toggler-icon"><i class="fas fa-bars"></i></span>
        </button>

        <div class="collapse navbar-collapse hide" id="navbar-expand-menu">
            <ul class="navbar-nav m-auto">
                <ng-container *ngIf="!customer">
                    <li class="nav-item pc-compo-login-hidden pb-3">
                        <p-dropdown [options]="languageItems" [(ngModel)]="selectedLanguage" optionLabel="name"
                            class="language-select" [filter]="false" filterBy="name" [showClear]="false"
                            placeholder="Select a Language" (onChange)="languageUse($event)">
                            <ng-template pTemplate="selectedItem">
                                <div class="" *ngIf="selectedLanguage">
                                    <img [src]="selectedLanguage.src" height="16px" />
                                    <div class="name-language">{{selectedLanguage.name}}</div>
                                </div>
                            </ng-template>
                            <ng-template let-language pTemplate="item">
                                <div class="">
                                    <img [src]="language.value.src" height="16px" [class]="" />
                                    <div class="name-language">{{language.value.name}}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </li>
                    <li class="nav-item pc-compo-login-hidden">
                        <div class="position-login" (click)="openModalLogin()">
                            <button type="button" class="btn btn-outline-primary">{{'sign_in' | language}}</button>
                        </div>
                    </li>
                    <li class="nav-item pc-compo-login-hidden">
                        <div class="position-login" (click)="openModalRegister()">
                            <button type="button" class="btn btn-primary">{{'sign_up' | language}}</button>
                        </div>
                    </li>
                </ng-container>

                <li *ngIf="customer && customer.Store && customer.Store !== 'null' && AppURL" class="nav-item dropdown pc-compo-login-hidden">

                    <div class="display-dropdown" style="min-width: 230px;">
                        <a class="dropdown-item" routerLinkActive="active" (click)="gotoURL()"
                            [routerLink]="['/', AppURL.management, customer.Store, 'auctions']">
                            <i class="fa fa-gavel"></i> {{'auction_item' | language}}
                        </a>
                        <a class="dropdown-item" routerLinkActive="active" (click)="gotoURL()"
                            [routerLink]="['/', AppURL.management, customer.Store, 'auction', 'add']">
                            <i class="fas fa-plus-circle"></i> {{'add_auction' | language}}
                        </a>
                        <a class="dropdown-item" routerLinkActive="active" (click)="gotoURL()"
                            [routerLink]="['/', AppURL.management, customer.Store, 'manage-user']">
                            <i class="fas fa-users"></i> {{'bidder' | language}}
                        </a>
                        <a class="dropdown-item" routerLinkActive="active" (click)="gotoURL()"
                            [routerLink]="['/', AppURL.management, customer.Store, 'setting-auction']">
                            <i class="fas fa-cog"></i> {{'setting_auction' | language}}
                        </a>
                        <a class="dropdown-item" routerLinkActive="active" (click)="gotoURL()"
                            [routerLink]="['/', AppURL.management, customer.Store, 'setting-verify-user']">
                            <i class="fas fa-user-cog"></i> {{'bidder_verify' | language}}
                        </a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" routerLinkActive="active" (click)="gotoURL()"
                            [routerLink]="['/', AppURL.management, customer.Store, 'payments']">
                            <i class="far fa-credit-card"></i> {{'payment' | language}}
                        </a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" routerLinkActive="active" (click)="gotoURL()"
                            [routerLink]="['/', AppURL.management, customer.Store, 'profile']">
                            <i class="fas fa-user"></i> {{'profile2' | language}}
                        </a>
                        <a class="dropdown-item" routerLinkActive="active" (click)="gotoURL()"
                            [routerLink]="['/', AppURL.management, customer.Store, 'profile-shop']">
                            <i class="fas fa-store-alt"></i> {{'shop_profile' | language}}
                            <span class="ml-2 badge-s badge-success" *ngIf="merchantStatus">{{'shop_open' | language}}</span>
                            <span class="ml-2 badge-s badge-secondary" *ngIf="!merchantStatus">{{'shop_closed' | language}}</span>
                        </a>
                        <a class="dropdown-item" routerLinkActive="active" (click)="gotoURL()"
                            [routerLink]="['/', AppURL.management, customer.Store, 'connect-another']">
                            <i class="fas fa-user-check"></i> {{'connect_another' | language}}
                        </a>
                        <a class="dropdown-item" routerLinkActive="active" (click)="gotoURL()"
                            [routerLink]="['/', AppURL.management, customer.Store, 'verify-identiry']">
                            <i class="fas fa-user-check"></i> {{'verify' | language}}
                        </a>
                        <a class="dropdown-item">
                            <p-dropdown [options]="languageItems" [(ngModel)]="selectedLanguage" optionLabel="name"
                                class="language-select" [filter]="false" filterBy="name" [showClear]="false"
                                placeholder="Select a Language" (onChange)="languageUse($event)">
                                <ng-template pTemplate="selectedItem">
                                    <div class="" *ngIf="selectedLanguage">
                                        <img [src]="selectedLanguage.src" height="16px" />
                                        <div class="name-language">{{selectedLanguage.name}}</div>
                                    </div>
                                </ng-template>
                                <ng-template let-language pTemplate="item">
                                    <div class="">
                                        <img [src]="language.value.src" height="16px" [class]="" />
                                        <div class="name-language">{{language.value.name}}</div>
                                    </div>
                                </ng-template>
                            </p-dropdown>
                        </a>
                        <a class="dropdown-item" (click)="onLogout()">
                            <i class="fas fa-sign-out-alt"></i> {{'sign_out' | language}}
                        </a>
                    </div>
                </li>
            </ul>
        </div>

        <div class="position-relative pc-compo-login-show" style="width: 0px;height: 0px;" *ngIf="customer">
            <div class="position-absolute d-flex position-login">
                <div class="d-flex ml-auto mr-4">
                    <div class="my-auto">
                        <p-dropdown [options]="languageItems" [(ngModel)]="selectedLanguage" optionLabel="name"
                            class="language-select" [filter]="false" filterBy="name" [showClear]="false"
                            placeholder="Select a Language" (onChange)="languageUse($event)">
                            <ng-template pTemplate="selectedItem">
                                <div class="" *ngIf="selectedLanguage">
                                    <img [src]="selectedLanguage.src" height="16px" />
                                    <div class="name-language">{{selectedLanguage.name}}</div>
                                </div>
                            </ng-template>
                            <ng-template let-language pTemplate="item">
                                <div class="">
                                    <img [src]="language.value.src" height="16px" [class]="" />
                                    <div class="name-language">{{language.value.name}}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>
                </div>
                <div class="onpage-notify" [ngClass]="{'d-none': !onNotify}">
                    <div class="onpage-compo-i">
                        <i class="far fa-bell"></i>
                    </div>
                </div>
    
                <div class="my-notify" [ngClass]="{'d-none': onNotify}">
                    <a data-toggle="dropdown" style="position: relative;" (click)="openpopUp()">
                        <i class="far fa-bell"></i>
                        <div class="compo-bugget" *ngIf="notiData && notiData.UnSeen > 0" >
                            <div class="bugget" [ngClass]="{'min10': notiData.UnSeen < 10 }">
                                <span>{{ notiData.UnSeen < 100 ? notiData.UnSeen : '+99' }}</span>
                            </div>
                        </div>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" style="min-width: 230px;">
                        <ng-container *ngIf="notiData && notiData.items" >
                            <ng-container *ngFor="let notiItem of notiItems; let index = index">
                                <a class="dropdown-item " [ngClass]="{'unread-item': !notiItem.Read }" (click)="nextPageNotify(index)">
                                    <div class="component-notify">
                                        <div class="component-icon">
                                            <div class="icon-notify" [ngClass]="{ 'success': notifyCheck('color-success', notiItem.Type), 'error': notifyCheck('color-error', notiItem.Type)}">
                                                <i class="fas fa-shield-alt"  *ngIf="notifyCheck('icon-verify', notiItem.Type)"></i>
                                                <i class="fas fa-user" *ngIf="notifyCheck('icon-joinAuction', notiItem.Type)"></i>
                                                <i class="fas fa-gavel" *ngIf="notifyCheck('icon-auction', notiItem.Type)"></i>
                                                <i class="fas fa-store" *ngIf="notifyCheck('icon-store', notiItem.Type)"></i>
                                                <i class="far fa-credit-card" *ngIf="notifyCheck('icon-card', notiItem.Type)"></i>
                                            </div>
                                        </div>
                                        <div class="component-detail">
                                            <div class="text-detail">
                                                <span class="word-break-all" *ngIf="notiItem.Type === 'RV'">
                                                    <strong>{{ notiItem.FromName }}</strong> {{'send_bidder_verification_request_auction' | language}} <strong>{{ notiItem.ToName }}</strong>
                                                </span>
                                                <span class="word-break-all" *ngIf="notiItem.Type === 'RVS'">
                                                    {{'bidder_verification_request_auction' | language}} <strong>{{ notiItem.ToName }}</strong> <strong class="text-success"> {{'approved_success' | language}}</strong>
                                                </span>
                                                <span class="word-break-all" *ngIf="notiItem.Type === 'RVF'">
                                                    {{'bidder_verification_request_auction' | language}} <strong>{{ notiItem.ToName }}</strong> <strong class="text-danger"> {{'rejected' | language}}</strong>
                                                </span>
                                                <span class="word-break-all" *ngIf="notiItem.Type === 'RB'">
                                                    <strong>{{ notiItem.FromName }} </strong> {{'request_join_auction_item' | language}} <strong> {{ notiItem.ToName }}</strong>
                                                </span>
                                                <span class="word-break-all" *ngIf="notiItem.Type === 'RBS'">
                                                    {{'request_join2' | language}} <strong>{{ notiItem.ToName }}</strong> <strong class="text-success"> {{'approved_success' | language}}</strong>
                                                </span>
                                                <span class="word-break-all" *ngIf="notiItem.Type === 'RBF'">
                                                    {{'request_join2' | language}} <strong>{{ notiItem.ToName }}</strong> <strong class="text-danger"> {{'rejected' | language}}</strong>
                                                </span>
                                                <span class="word-break-all" *ngIf="notiItem.Type === 'AS'">
                                                    {{'list' | language}} <strong>{{ notiItem.ToName }}</strong> {{'start_now' | language}}
                                                </span>
                                                <span class="word-break-all" *ngIf="notiItem.Type === 'AW'">
                                                    {{'list' | language}} <strong>{{ notiItem.ToName }}</strong> <strong class="text-success"> {{'you_winner' | language}}</strong>
                                                </span>
                                                <span class="word-break-all" *ngIf="notiItem.Type === 'AL'">
                                                    {{'list' | language}} <strong>{{ notiItem.ToName }}</strong> <strong class="text-danger"> {{'you_loser' | language}}</strong>
                                                </span>
                                                <span class="word-break-all" *ngIf="notiItem.Type === 'MVR'">
                                                    <strong>{{ notiItem.ToName }}</strong> {{'your_shop_sent_verification_request' | language}}
                                                </span>
                                                <span class="word-break-all" *ngIf="notiItem.Type === 'MVS'">
                                                    {{'shop_identity_verification_request' | language}} <strong>{{ notiItem.ToName }}</strong> <strong class="text-success"> {{'approved_success' | language}}</strong>
                                                </span>
                                                <span class="word-break-all" *ngIf="notiItem.Type === 'MVF'">
                                                    {{'shop_identity_verification_request' | language}} <strong>{{ notiItem.ToName }}</strong> <strong class="text-danger"> {{'rejected' | language}}</strong>
                                                </span>
                                                <span class="word-break-all" *ngIf="notiItem.Type === 'SP'">
                                                    {{'package_payment_completed' | language}}
                                                </span>
                                                <span class="word-break-all" *ngIf="notiItem.Type === 'SA'">
                                                    {{'received_package_admin' | language}}
                                                </span>
                                            </div>
                                            <p class="text-time" *ngIf="notiItem?.settime">{{ notiItem?.settime }}</p>
                                        </div>
                                    </div>
                                </a>
                            </ng-container>
                        </ng-container>
                        <a class="dropdown-item see-all unread-item"
                          *ngIf="notiItems && notiItems.length > 0"
                          (click)="nextPageNotify(-1)">
                            <p class="text-center">{{'more_all' | language}}</p>
                        </a>
                        <a class="dropdown-item see-all unread-item"
                          *ngIf="!notiItems || notiItems.length === 0">
                            <p class="text-center">{{'no_notifications' | language}}</p>
                        </a>
                    </div>
                </div>
                
                <div class="my-profile" *ngIf="customer && customer.Store && customer.Store !== 'null'">
                    <a class="nav-link dropdown-toggle pr-0 pl-0" data-toggle="dropdown">
                        <img class="img-customer" *ngIf="urlImage" [src]="urlImage" alt="" />
                        <img class="img-customer" *ngIf="!urlImage" [src]="urlImageProfile" alt="" />
                    </a>
        
                    <div class="dropdown-menu dropdown-menu-right" style="min-width: 230px;">
                        <a class="dropdown-item" routerLinkActive="active"
                            [routerLink]="['/', AppURL.management, customer.Store, 'auctions']">
                            <i class="fa fa-gavel"></i> {{'auction_item' | language}}
                        </a>
                        <a class="dropdown-item" routerLinkActive="active"
                            [routerLink]="['/', AppURL.management, customer.Store, 'auction', 'add']">
                            <i class="fas fa-plus-circle"></i> {{'add_auction' | language}}
                        </a>
                        <a class="dropdown-item" routerLinkActive="active"
                            [routerLink]="['/', AppURL.management, customer.Store, 'manage-user']">
                            <i class="fas fa-users"></i> {{'bidder' | language}}
                        </a>
                        <a class="dropdown-item" routerLinkActive="active"
                            [routerLink]="['/', AppURL.management, customer.Store, 'setting-auction']">
                            <i class="fas fa-cog"></i> {{'setting_auction' | language}}
                        </a>
                        <a class="dropdown-item" routerLinkActive="active"
                            [routerLink]="['/', AppURL.management, customer.Store, 'setting-verify-user']">
                            <i class="fas fa-user-cog"></i> {{'bidder_verify' | language}}
                        </a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" routerLinkActive="active"
                            [routerLink]="['/', AppURL.management, customer.Store, 'payments']">
                            <i class="far fa-credit-card"></i> {{'payment' | language}}
                        </a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" routerLinkActive="active"
                            [routerLink]="['/', AppURL.management, customer.Store, 'profile']">
                            <i class="fas fa-user"></i> {{'profile2' | language}}
                        </a>
                        <a class="dropdown-item" routerLinkActive="active"
                            [routerLink]="['/', AppURL.management, customer.Store, 'profile-shop']">
                            <i class="fas fa-store-alt"></i> {{'shop_profile' | language}}
                            <span class="ml-2 badge-s badge-success" *ngIf="merchantStatus">{{'shop_open' | language}}</span>
                            <span class="ml-2 badge-s badge-secondary" *ngIf="!merchantStatus">{{'shop_closed' | language}}</span>
                        </a>
                        <a class="dropdown-item" routerLinkActive="active" (click)="gotoURL()"
                            [routerLink]="['/', AppURL.management, customer.Store, 'connect-another']">
                            <i class="fas fa-link"></i> {{'connect_another' | language}}
                        </a>
                        <a class="dropdown-item" routerLinkActive="active"
                            [routerLink]="['/', AppURL.management, customer.Store, 'verify-identiry']">
                            <i class="fas fa-user-check"></i> {{'verify' | language}}
                        </a>
                        <a class="dropdown-item" (click)="onLogout()">
                            <i class="fas fa-sign-out-alt"></i> {{'sign_out' | language}}
                        </a>
                    </div>
                </div>
            </div>

        </div>

        <div class="position-relative pc-compo-login-show" style="width: 0px;height: 0px;" *ngIf="!customer">
            <div class="position-absolute d-flex position-login">
                <div class="mr-3">
                    <p-dropdown [options]="languageItems" [(ngModel)]="selectedLanguage" optionLabel="name"
                        class="language-select" [filter]="false" filterBy="name" [showClear]="false"
                        placeholder="Select a Language" (onChange)="languageUse($event)">
                        <ng-template pTemplate="selectedItem">
                            <div class="" *ngIf="selectedLanguage">
                                <img [src]="selectedLanguage.src" height="16px" />
                                <div class="name-language">{{selectedLanguage.name}}</div>
                            </div>
                        </ng-template>
                        <ng-template let-language pTemplate="item">
                            <div class="">
                                <img [src]="language.value.src" height="16px" [class]="" />
                                <div class="name-language">{{language.value.name}}</div>
                            </div>
                        </ng-template>
                    </p-dropdown>
                </div>
                <button type="button" class="btn btn-outline-primary" (click)="openModalLogin()">{{'sign_in' | language}}</button>
                <button type="button" class="btn btn-primary" (click)="openModalRegister()">{{'sign_up' | language}}</button>
            </div>
        </div>
    </div>
</nav>

<ng-template #loginModal>
    <kfa-modal-login [modalLogin]="modalRefLogin" (onLoadModal)="onLoadModalLoginComponent($event)"></kfa-modal-login>
</ng-template>

<ng-template #rankModal>
    <kfa-modal-rank (Close)="closeModal()"></kfa-modal-rank>
</ng-template>

<ng-template #lossModal>
    <kfa-modal-loss (Close)="closeModal()"></kfa-modal-loss>
</ng-template>

<ng-template #verifyModal>
    <kfa-modal-verify [modalVerify]="modalRefVerify" [fixPhone]="true"> </kfa-modal-verify>
</ng-template>

<ng-container *ngIf="environmentData && environmentData.bidder === 'https://dev.dee.auction/'">
    <iframe src="https://dev.dee.auction/safe" frameborder="0" id="frameBidder" hidden></iframe>
</ng-container>
<ng-container *ngIf="environmentData && environmentData.bidder === 'https://dee.auction/'">
    <iframe src="https://dee.auction/safe" frameborder="0" id="frameBidder" hidden></iframe>
</ng-container>