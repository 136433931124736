import { Component, OnInit, ViewChild, ElementRef, OnDestroy, Output, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'kfa-modal-loss',
  templateUrl: './modal-loss.component.html',
  styleUrls: ['./modal-loss.component.scss']
})
export class ModalLossComponent implements OnInit, OnDestroy {
  @Output() Close = new EventEmitter();

  @ViewChild('audioOption', { static: true }) audioPlayerRef: ElementRef<HTMLAudioElement>;

  content = environment.content;

  AuctionLoss: IAuction;
  Subscriptions: Subscription[] = [];

  constructor(private userSv: UserService) {
    this.Subscriptions.push(
      this.userSv.loser.subscribe(res => {
        this.AuctionLoss = res;
      })
    );
  }

  ngOnInit() {
    this.onAudioPlay();
  }

  ngOnDestroy() {
    this.Subscriptions.map(s => s.unsubscribe());
    this.Subscriptions = [];
    this.userSv.setLoser(null);
    this.Close.emit(true);
  }

  onAudioPlay() {
    this.audioPlayerRef.nativeElement.play();
    setTimeout(() => {
      this.audioPlayerRef.nativeElement.pause();
    }, 5000);
  }

  closeModal() {
    this.Close.emit(true);
  }
}
