import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FavoritService {
  favoriteSubscription = new Subject<{ aucId: number; isFav: boolean }>();

  constructor(private http: HttpClient) {}

  CreateFavorit(aucId) {
    return this.http.post<any>(`favorite/auction/create/${aucId}`, null);
  }
  DeleteFavorit(auctionId) {
    return this.http.delete(`favorite/auction/delete/byAuctionId/${auctionId}`);
  }

  GetListFavorite() {
    return this.http.get('favorite/auction/list');
  }

  getFavoriteAucionBidder(pagi: IModelPager) {
    const pageParams = `page=${pagi.page}&limit=${pagi.limit}&name=${pagi.name}&filter=${pagi.filter}`;
    const pageFilter = `status=${pagi.status}&search=${pagi.search}&categoryCarId=`;
    return this.http.get<IPagination<IAuction>>(`favorite/auction/bidder/pagination?${pageFilter}&${pageParams}`);
  }

  getFavoriteAucionOwner(pagi: IModelPager) {
    const pageParams = `page=${pagi.page}&limit=${pagi.limit}&name=${pagi.name}&filter=${pagi.filter}`;
    const pageFilter = `status=${pagi.status}&search=${pagi.search}`;
    return this.http.get<IPagination<IAuction>>(`favorite/auction/owner/pagination?${pageFilter}&${pageParams}`);
  }

  /** ดึงข้อมูลสินค้าที่เป็นรายการโปรด */
  getFavoriteProduct(search: string,categoryCarId: number) {
    return this.http.get<any>(`favorite/product/bycustomer/pagination?search=${search}&categoryCarId=${categoryCarId}`);
  }

  /** สร้างรายการโปรดของ Product */
  createFavoriteProduct(prodId: number) {
    return this.http.post<any>(`favorite/product/create/${prodId}`,null);
  }

  /** ลบรายการโปรดของ Product */
  removeFavoriteProduct(prodId: number) {
    return this.http.delete<any>(`favorite/delete/byproduct/${prodId}`);
  }
}
