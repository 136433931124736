import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  constructor(private http: HttpClient) {

  }


  CreatCustomer(item) {
    return this.http.post('authen/signup', item);
  }


}


