import { Injectable, Inject } from '@angular/core';
import { LOCAL_STORAGE } from '@ng-toolkit/universal';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  constructor(@Inject(LOCAL_STORAGE) private localStorage: any) {}

  set(key: string, value: any) {
    if (value) {
      this.localStorage.setItem(key, JSON.stringify(value || ''));
    } else {
      this.remove(key);
    }
  }

  get(key: string) {
    try {
      let RefCode;
      try {
        RefCode = JSON.parse(this.localStorage.getItem(key));
        return RefCode;
      } catch (error) {
          // console.log('Error parsing JSON:', error, this.localStorage.getItem(key));
      }
    } catch (error) {
      return null;
    }
  }

  remove(key: string) {
    this.localStorage.removeItem(key);
  }

  clear() {
    this.localStorage.clear();
  }
}
