import { NgModule } from '@angular/core';
import { GalleryModule } from '@ngx-gallery/core';

@NgModule({
  declarations: [],
  imports: [
    GalleryModule.withConfig({
      loadingMode: 'indeterminate'
    }),
  ],
  exports: [
    GalleryModule,
  ],
})
export class NgxGalleryModule { }
