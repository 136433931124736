import { Component, AfterViewInit, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { LoadScriptsService } from './services/load-scripts.service';
import { Title } from '@angular/platform-browser';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import AOS from 'aos';
import { AppService } from './app.service';
import { KEY_TOKEN } from './models/app.models';

@Component({
  selector: 'kfa-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  title = 'DeeAuction';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
    private loadScripts: LoadScriptsService,
    private svTitle: Title,
    private svApp: AppService
  ) {
    

    this.loadScripts.loadScripts().then(() => { });
    this.listenRoutes();
  }

  ngAfterViewInit() {
    AOS.init();
  }

  listenRoutes() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          let route = this.route.firstChild;
          let child = route;
          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
              route = child;
            } else {
              child = null;
            }
          }
          return route;
        }),
        mergeMap(route => route.data)
      )
      .subscribe(data => {
        if (this.svApp.isBrowser) {
          setTimeout(() => {
            AOS.refreshHard();
          }, 1000);
        }

        if (data.title) {
          this.svTitle.setTitle(data.title);
        } else {
          if (typeof data.title === 'string') {
            this.svTitle.setTitle(this.title);
          }
        }
      });
  }
}
