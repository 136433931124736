<div class="pinch-layout">
  <pinch-zoom #pinchZoom>
    <img
      [src]="this.selectedItem?.MedPath"
      [attr.zoom]="this.selectedItem?.MedPath"
      style="width: 100%;"
      height="400px"
      [ngClass]="{'on-popup-img-set': onPopupImage}"
    />
  </pinch-zoom>
  <ng-container *ngIf="items.length > 1">
    <span class="arrow arrow-right" (click)="nextImage()">
      <i class="fas fa-chevron-right"></i>
    </span>
    <span class="arrow arrow-left" (click)="prevImage()">
      <i class="fas fa-chevron-left"></i>
    </span>
  </ng-container>
</div>

<ul id="images" class="images" *ngIf="items">
  <li
    [id]="item.AucMedId"
    class="image-item cursor"
    *ngFor="let item of items"
    [ngClass]="{ active: item.AucMedId === this.selectedItem?.AucMedId }"
    (click)="onSelectItem(item)"
  >
    <img [src]="item.MedPath" alt="" style="width: 100px; height: 100px;" />
  </li>
</ul>
