import { Component, OnInit, Input, TemplateRef, ViewChild, Inject, EventEmitter, Output } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { LoginService } from '../../services/login/login.service';
import Swal from 'sweetalert2';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { RegisterService } from '../../services/register/register.service';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
import { WINDOW } from '@ng-toolkit/universal';
import { finalize } from 'rxjs/operators';
import { CustomerService } from 'src/app/services/customer.service';
import { KEY_USER, KEY_TOKEN } from 'src/app/models/app.models';
import { AppService } from 'src/app/app.service';
import { timer } from 'rxjs';
import { LanguagePipe } from '../../pipes/language.pipe';

@Component({
  selector: 'kfa-modal-login',
  templateUrl: './modal-login.component.html',
  styleUrls: ['./modal-login.component.scss'],
  providers: [LanguagePipe]
})
export class ModalLoginComponent implements OnInit {
  @Input() modalLogin: BsModalRef;

  @Output('onLoadModal') onLoadModal = new EventEmitter();

  @ViewChild('verifyModal', { static: true }) verifyModal: TemplateRef<any>;
  modalRefVerify: BsModalRef;

  @ViewChild('RequestEmailForgetPasswordModal', { static: true }) RequestEmailForgetPasswordModal: TemplateRef<any>;
  modalEmailForgetPassword: BsModalRef;
  @ViewChild('RequestTelForgetPasswordModal', { static: true }) RequestTelForgetPasswordModal: TemplateRef<any>;
  modalTelForgetPassword: BsModalRef;

  @ViewChild('ChooseForgetPasswordModal', { static: true }) ChooseForgetPasswordModal: TemplateRef<any>;
  modalChooseForgetPassword: BsModalRef;

  formLogin: FormGroup;
  fromRegister: FormGroup;
  formRequestTelForgetPassword: FormGroup;
  formRequestEmailForgetPassword: FormGroup;
  fromOTPResetPassword: FormGroup;

  tab = {
    login: 'login',
    register: 'register'
  };
  activeTab = 'login';

  otpTimeOut = false;

  configTime = { leftTime: 300 };

  separateDialCode = true;
  sendEmailRequest = false;
  sendTelRequest = false;
  sendOTPRequest = false;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  phoneForm = new FormGroup({
    phone: new FormControl(undefined, [Validators.required])
  });

  loading: boolean;

  constructor(
    private builder: FormBuilder,
    private modalService: BsModalService,
    private serviceLogin: LoginService,
    private registerService: RegisterService,
    private storage: LocalStorageService,
    @Inject(WINDOW) private window: Window,
    private svCustomer: CustomerService,
    private languageSer: LanguagePipe,
    private svApp: AppService
  ) {
    if (this.serviceLogin.getModelPageName() === 'login') {
      this.activeTab = this.tab.login;
    } else {
      this.activeTab = this.tab.register;
    }
    this.createFormLogin();
    this.createFormRegister();
    this.createformRequestTelForgetPassword();
    this.createformRequestEmailForgetPassword();
    this.createFormOTPResetPassword();
  }

  ngOnInit() {
    this.onLoadModal.emit(this);
  }

  openModalVerify() {
    this.modalRefVerify = this.modalService.show(this.verifyModal, {
      class: 'modal-md transparent',
      ignoreBackdropClick: true
    });
  }

  openTelForgetPassword() {
    this.modalChooseForgetPassword.hide();
    this.sendTelRequest = false;
    this.createformRequestTelForgetPassword();
    this.modalTelForgetPassword = this.modalService.show(this.RequestTelForgetPasswordModal, {
      class: 'modal-md transparent',
      ignoreBackdropClick: false,
      backdrop: true
    });
  }

  openEmailForgetPassword() {
    this.modalChooseForgetPassword.hide();
    this.sendEmailRequest = false;
    this.createformRequestEmailForgetPassword();
    this.modalEmailForgetPassword = this.modalService.show(this.RequestEmailForgetPasswordModal, {
      class: 'modal-md transparent',
      ignoreBackdropClick: false,
      backdrop: true
    });
  }

  openChooseForgetPassword() {
    this.modalLogin.hide();
    this.modalChooseForgetPassword = this.modalService.show(this.ChooseForgetPasswordModal, {
      class: 'modal-md transparent',
      ignoreBackdropClick: false,
      backdrop: true
    });
  }

  setActiveTab(name) {
    this.activeTab = name;
  }

  createFormLogin() {
    this.formLogin = this.builder.group({
      username: ['', [Validators.required,this.checkformat]],
      password: ['', [Validators.required]]
    });
  }

  createFormOTPResetPassword() {
    this.fromOTPResetPassword = this.builder.group({
      Token: ['', [Validators.required]],
      OTPRef: [''],
      OTPCode: [''],
      NewPassword: [''],
      ConfirmPassword: ['']
    });
  }

  createFormRegister() {
    this.fromRegister = this.builder.group({
      CustomerEmail: ['', [Validators.required]],
      CustomerUsername: ['', [Validators.required]],
      CustomerPassword: ['', [Validators.required]],
      CustomerConfirmPassword: ['', [Validators.required]],
      phone: [undefined, [Validators.required]]
    });
  }

  createformRequestTelForgetPassword() {
    this.formRequestTelForgetPassword = this.builder.group({
      Type: ['sms', [Validators.required]],
      Value: [''],
      CountryCode: [''],
      phone: [undefined, [Validators.required]]
    });
  }
  createformRequestEmailForgetPassword() {
    this.formRequestEmailForgetPassword = this.builder.group({
      Type: ['email', [Validators.required]],
      Value: ['', [Validators.required]]
    });
  }

  submitLogIn() {

    if (this.formLogin.invalid) {
      return this.formLogin.markAllAsTouched();
    }

    Swal.fire({
      title: this.languageSer.transform('loading')+'...',
      timer: 50000,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading();
      }
    });

    this.serviceLogin.signIn(this.formLogin.value).subscribe(
      (res: any) => {
        this.storage.set(KEY_TOKEN, `${res.Token}`);

        if (res.User.Status === 'dea') {
          Swal.fire({
            position: 'center',
            icon: 'warning',
            text: this.languageSer.transform('please_verify_before_accessing'),
            confirmButtonText: this.languageSer.transform('continue'),
            showCloseButton: true
          }).then(_ => {
            this.modalLogin.hide();
            this.openModalVerify();
          });
        } else if (res.User.Status === 'act') {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: this.languageSer.transform('login_success'),
            showConfirmButton: false,
            timer: 1000
          }).then(_ => {
            if (this.svApp.isBrowser) {
              location.href = ['', res.User.Store,'managements', 'auctions'].join('/');
              // this.window.location.reload();
            }
            this.modalLogin.hide();
            // this.svCustomer.updateCustomer$.next();
          });
        }
      },
      error => {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: this.languageSer.transform('error'),
          text: `${error.error.Message}`,
          showConfirmButton: false,
          showCloseButton: true
        });
      }
    );
  }

  onRegister() {
    if (this.fromRegister.invalid) return this.fromRegister.markAllAsTouched();
    Swal.fire({
      title: this.languageSer.transform('loading')+'...',
      timer: 50000,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading();
      }
    }).then(result => { });
    if (!this.fromRegister.get('phone').invalid) {
      if (this.fromRegister.valid) {
        if (this.fromRegister.value.CustomerPassword === this.fromRegister.value.CustomerConfirmPassword) {
          const strCountryCode: string = this.fromRegister.value.phone.dialCode;
          const newCustomerCountryCode = strCountryCode.split('+');

          const strNumberPhone: string = this.fromRegister.value.phone.number;
          const NumberPhone = strNumberPhone.replace(/ /g, '').replace(/-/g, '');

          let newNumberPhone = '';
          for (let i = 0; i < NumberPhone.length; i++) {
            newNumberPhone = newNumberPhone + NumberPhone[i];
          }

          const objRegister = {
            CustomerEmail: this.fromRegister.value.CustomerEmail,
            CustomerUsername: this.fromRegister.value.CustomerUsername,
            CustomerCountryCode: newCustomerCountryCode[1],
            CustomerPhoneNo: newNumberPhone,
            CustomerPassword: this.fromRegister.value.CustomerPassword
          };
          this.registerService.CreatCustomer(objRegister).subscribe(
            res => {
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: this.languageSer.transform('successful_registration'),
                showConfirmButton: false,
                timer: 1500
              }).then(wait => {
                this.modalLogin.hide();
                this.onLogin();
              });
            },
            error => {
              Swal.fire({
                position: 'center',
                icon: 'error',
                text: `${error.error.Message}`,
                showConfirmButton: false,
                timer: 1500
              });
            }
          );
        } else {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: this.languageSer.transform('passwords_not_match'),
            showConfirmButton: false,
            timer: 1500
          });
        }
      } else {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: this.languageSer.transform('please_complete_information'),
          showConfirmButton: false,
          timer: 1500
        });
      }
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: this.languageSer.transform('invalid_phone'),
        showConfirmButton: false,
        timer: 1500
      });
    }
  }

  onLogin() {
    const obj = {
      username: this.fromRegister.value.CustomerEmail,
      password: this.fromRegister.value.CustomerPassword
    };

    this.serviceLogin.signIn(obj).subscribe(
      res => {
        this.storage.set(KEY_TOKEN, `${res.Token}`);

        this.fromRegister.reset();
        this.openModalVerify();
      },
      error => { }
    );
  }

  onEmailRequestForgetPassword() {
    if (this.formRequestEmailForgetPassword.valid) {
      this.loading = true;
      this.serviceLogin
        .forgot_password(this.formRequestEmailForgetPassword.value)
        .pipe(finalize(() => (this.loading = false)))
        .subscribe(
          res => {
            this.sendEmailRequest = true;
          },
          err => {
            Swal.fire({
              position: 'center',
              icon: 'error',
              text: `${err.error.Message}`,
              showConfirmButton: false,
              timer: 1500
            }).then(result => { });
          }
        );
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: this.languageSer.transform('please_complete_information'),
        showConfirmButton: false,
        timer: 1500
      }).then(result => { });
    }
  }

  onTelRequestForgetPassword() {
    if (this.formRequestTelForgetPassword.valid) {
      let number = this.formRequestTelForgetPassword.value.phone.number.replace(/\s/g, '').replace(/-/g, '');
      let code = this.formRequestTelForgetPassword.value.phone.dialCode.replace('+', '');

      this.formRequestTelForgetPassword.patchValue({
        Value: number,
        CountryCode: code
      });
      this.loading = true;
      this.serviceLogin
        .forgot_password(this.formRequestTelForgetPassword.value)
        .pipe(finalize(() => (this.loading = false)))
        .subscribe(
          res => {
            this.sendTelRequest = true;
            this,
              this.fromOTPResetPassword.patchValue({
                Token: res.Token,
                OTPRef: res.OTPRef
              });
          },
          err => {
            Swal.fire({
              position: 'center',
              icon: 'error',
              text: `${err.error.Message}`,
              showConfirmButton: false,
              timer: 1500
            }).then(result => { });
          }
        );
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: this.languageSer.transform('data_incorrect'),
        showConfirmButton: false,
        timer: 1500
      }).then(result => { });
    }
  }

  OtpAgain() {
    this.serviceLogin.OTPagainForgetPassword(this.fromOTPResetPassword.value.Token).subscribe(
      res => {
        this.fromOTPResetPassword.patchValue({
          OTPRef: res.OTPRef,
          OTPCode: ''
        });
        this.configTime = { leftTime: 300 };
        this.otpTimeOut = false;
      },
      err => {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: `${err.error.Message}`,
          showConfirmButton: false,
          timer: 1500
        }).then(result => { });
      }
    );
  }

  finishTimeOTP($event) {
    if ($event.action === 'done') {
      this.otpTimeOut = true;
    }
  }

  sendOTPVerify() {
    if (
      this.fromOTPResetPassword.value.OTPRef &&
      this.fromOTPResetPassword.value.OTPCode &&
      this.fromOTPResetPassword.value.Token
    ) {
      this.serviceLogin.sendVerifyOTPForgetPassword(this.fromOTPResetPassword.value).subscribe(
        res => {
          if (res) {
            this.sendOTPRequest = true;
            this.sendTelRequest = true;
          }
        },
        err => {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: `${err.error.Message}`,
            showConfirmButton: false,
            timer: 1500
          }).then(result => { });
        }
      );
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: this.languageSer.transform('data_incorrect'),
        showConfirmButton: false,
        timer: 1500
      }).then(result => { });
    }
  }

  sendNewPasswordConfrim() {
    if (this.fromOTPResetPassword.value.NewPassword && this.fromOTPResetPassword.value.ConfirmPassword) {
      if (this.fromOTPResetPassword.value.NewPassword === this.fromOTPResetPassword.value.ConfirmPassword) {
        this.serviceLogin.sendResetPasswordForTel(this.fromOTPResetPassword.value).subscribe(
          res => {
            if (res) {
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: this.languageSer.transform('successful_new_password'),
                showConfirmButton: false,
                timer: 1500
              }).then(result => {
                this.modalTelForgetPassword.hide();
              });
            }
          },
          err => {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: `${err.error.message}`,
              showConfirmButton: false,
              timer: 1500
            }).then(result => { });
          }
        );
      } else {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: this.languageSer.transform('passwords_not_match'),
          showConfirmButton: false,
          timer: 1500
        }).then(result => { });
      }
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: this.languageSer.transform('please_complete_information'),
        showConfirmButton: false,
        timer: 1500
      }).then(result => { });
    }
  }

  /** เชคว่าข้อมูลที่กรอกอยู่ในรูปของ phone or email หรือไม่ */
  checkformat(inputControl: AbstractControl) {
    const checkEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const checkPhone = /^([0-9]{10,})$/; 
    const check:boolean = checkEmail.test(inputControl.value) || checkPhone.test(inputControl.value);
    return check ? null : { loginuser: true };
  }

}
