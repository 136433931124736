<div class="bg-white mt-5">
  <div class="modal-header">
    <h4 *ngIf="activeTab === 'select-verify'" style="font-size: 30px;">{{'select_identity_verification' | language}}</h4>
    <h4 *ngIf="activeTab === 'select-OTP'" style="font-size: 30px;">{{'verify_otp' | language}} </h4>
    <h4 *ngIf="activeTab === 'select-Email'" style="font-size: 30px;">{{'verify_email' | language}} </h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalVerify.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body py-4 px-5">

    <div *ngIf="activeTab === 'select-verify'" class="form-group mt-3">

      <label class="container">{{'verify_otp_sms_phone_number' | language}}
        <input type="radio" [checked]="selectType === 0" (click)="selectType = 0" name="radio">
        <span class="checkmark"></span>
      </label>
      <label class="container" *ngIf="!fixPhone">{{'verify_email_address' | language}}
        <input type="radio" [checked]="selectType === 1" (click)="selectType = 1" name="radio">
        <span class="checkmark"></span>
      </label>

    </div>

    <div *ngIf="activeTab === 'select-OTP'"  class="form-group mt-3">

      <form [formGroup]="formSendOTP">


      <div class="col-md-12 mt-2">
        <div class="row">
          <div class="col-md-4">
            <p style="font-size: 20px">{{'ref_code' | language}}</p>
          </div>
          <div class="col-md-8 form-group">
            <input style="font-size: 20px;" class="form-control" formControlName="OTPRef" disabled type="text">
          </div>
        </div>
      </div>

      <div class="col-md-12 mt-2">
        <div class="row">
          <div class="col-md-4">
            <p style="font-size: 20px">{{'otp_code' | language}}</p>
          </div>
          <div class="col-md-8 form-group">
            <input style="font-size: 20px;" formControlName="OTPCode" class="form-control" type="text">
          </div>
        </div>
      </div>

      <div class="col-md-12 mt-2">
        <div class="row">
          <div class="col-md-4">
            <p style="font-size: 20px">{{'time_left' | language}}</p>
          </div>
          <div class="col-md-4 form-group pt-0">
            <p style="font-size: 20px;">
              <countdown #countdown (event)="finishTimeOTP($event)"[config]="configTime">$!m!:$!s!</countdown>
            </p>
          </div>
          <div class="col-md-4 form-group pt-0">
              <a class="py-2 pt-4" style="font-size: 16px" (click)="OtpAgain()"> <i class="fas fa-redo"></i> {{'resend' | language}}</a>
          </div>
        </div>
      </div>

    </form>

    </div>

    <div *ngIf="activeTab === 'select-Email'" class="form-group mt-3 text-center">
      <h5 style="color: #0588E1">{{'we_have_sent_verification_email' | language}}</h5>
      <h5 style="color: #0588E1">{{'please_check_inbox_junk_box' | language}}</h5>
    </div>

    <div class="form-group mt-3">
      <button *ngIf="activeTab === 'select-verify'" (click)="chooseTypeVerify()" type="button"
      class="btn btn-primary-2 my-2">
        {{'verify' | language}}
      </button>
      <button *ngIf="activeTab === 'select-OTP' && otpTimeOut === false" (click)="sendOTP()" type="button"
      class="btn btn-primary-2 my-2">
      {{'confirm2' | language}} OTP
      </button>
      <button *ngIf="activeTab === 'select-OTP' && otpTimeOut === true"  type="button"
      class="btn btn-primary-disable my-2">
      {{'confirm2' | language}} OTP
      </button>
      <button *ngIf="activeTab === 'select-Email'" (click)="modalVerify.hide()" type="button"
      class="btn btn-primary-2 my-2">
        {{'cancel' | language}}
      </button>
    </div>
  </div>

</div>
